import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';

const postSite = (enqueueSnackbar: any, body: any) => {
    callPostAPI('site',
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar);
            WebStore.setSelectedSiteId(response, true);
        },
        enqueueSnackbar,
        'Site added successfully'
    );
};

export default postSite;