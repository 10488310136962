/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Fragment} from 'react';
import {observer} from 'mobx-react-lite';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import {IconButton, styled} from '@mui/material';
import {Add, Remove, ZoomOutMap} from '@mui/icons-material';

interface Props {
    children: object
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

const SvgZoomPanComponent = observer((props: Props) => (
    <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform }) => (
            <Fragment>
                <div>
                    <Row>
                        <IconButton
                            onClick={() => zoomIn()}
                        >
                            <Add fontSize="small" />
                        </IconButton>

                        <IconButton
                            onClick={() => zoomOut()}
                        >
                            <Remove fontSize="small" />
                        </IconButton>

                        <IconButton
                            onClick={() => resetTransform()}
                        >
                            <ZoomOutMap fontSize="small" />
                        </IconButton>
                    </Row>
                    <TransformComponent
                        wrapperStyle={{ width: '100%' }}
                        contentStyle={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {props.children}
                    </TransformComponent>
                </div>
            </Fragment>
        )}
    </TransformWrapper>
    ));

export default SvgZoomPanComponent;
