/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import {
    Association,
    BinGraphData,
    BinLog,
    BinNote,
    CarouselItem,
    Command,
    CommunicationTable,
    Country,
    CustomerReportData,
    Dealer,
    DealerUserData,
    DeviceSettingsStructure,
    Grain,
    InventoryTableSite,
    DataModel,
    MyUsersTableData,
    NotesTableData,
    NotificationEmail,
    NotificationPhoneNumber,
    ServerCommandsGroup,
    StorageListBin,
    UserData,
    WeatherData,
    MyCustomersTableData,
    AutomationDevicesData,
    CableAssociation,
    FanAssociation,
    AutomationAccessory,
    Difference,
    SiteStructures,
    LostDevice,
    HighTemperatureData,
    ServiceRecord,
    AutomationDefaults,
    SiteGraphData,
    SiteConditions,
    DealerNotification
} from '../types/components/ApiTypes';
import {configure} from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import DealerReportsData from '../helper/temp/DealerReportsData';
import CustomerUsersData from '../helper/temp/CustomerUsersData';
import AlertsData from '../helper/temp/AlertsData';
import presetUserData from '../helper/temp/presetUserData';
import presetDealerUserModel from '../helper/temp/presetDealerUserModel';
import Constants from '../helper/Constants';
import presetCommunicationSummaryData from '../helper/temp/presetCommunicationSummaryData';

configure({
    useProxies: 'never',
});

const presetModelData = {
    grains: [] as Grain[],
    countries: [] as Country[],
    dealers: [] as Dealer[],
    serverCommandsGroupMap: [] as ServerCommandsGroup[],
    timeZones: [],
    automationDefaults: {} as AutomationDefaults,
};

const presetWeatherData = {
    currentTemperature: 0,
    forecastedHighTemperature: 0,
    forecastedLowTemperature: 0,
    humidity: 0,
    windSpeed: 0,
    windGusts: 0,
    POP: 0,
};

const presetSiteConditions: SiteConditions = {
    ambientTemp: null,
    humidity: null,
    tempTrend: null,
    humidityTrend: null,
};

const presetCarouselData = [ {
    type: '',
    url: '',
    title: '',
    subtext: '',
    src: '',
} ];

const presetSiteStructuresData = {
    id: -1,
    automationHubs: [],
    bins: [],
    grainBags: [],
    buildings: [],
    structNames: [],
    dataGeneratedTime: new Date,
};

class BinsenseStore {
    error = '';

    success = {
        title: '',
        message: '',
    }

    confirm: { title: string; message: string; onConfirm: () => void } = {
        title: '',
        message: '',
        onConfirm: () => {
        },
    }

    awaitingUserData = true;

    awaitingApi = false;

    dataModel: DataModel = cloneDeep(presetModelData);

    userData: UserData = cloneDeep(presetUserData);

    dealerUserData: DealerUserData = cloneDeep(presetDealerUserModel);

    deviceTableData: DeviceSettingsStructure[] = [] as DeviceSettingsStructure[];

    storageListData: StorageListBin[] = [] as StorageListBin[];

    binNotesData: BinNote[] = [] as BinNote[];

    notesTableData: NotesTableData[] = [] as NotesTableData[];

    serviceRecordsData: ServiceRecord[] = [] as ServiceRecord[];

    binLogsData: BinLog[] = [] as BinLog[];

    customerReportsData: CustomerReportData[] = [] as CustomerReportData[];

    customerSiteData: MyCustomersTableData[] = [] as MyCustomersTableData[];

    dealerReportsData: any[] = cloneDeep(DealerReportsData.dealerReportsData);

    myUsersData: MyUsersTableData[] = [] as MyUsersTableData[]

    differencesData: Difference[] = [] as Difference[]

    commandsData: any[] = []

    commandHistoryData: Command[] = [] as Command[]

    commandCount = 0

    customerUsersData: any[] = cloneDeep(CustomerUsersData.customerUsersData)

    associationsData: Association[] = [] as Association[]

    cableAssociationsData: CableAssociation[] = [] as CableAssociation[]

    fanAssociationsData: FanAssociation[] = [] as FanAssociation[]

    automationAccessoriesData: AutomationAccessory[] = [] as AutomationAccessory[]

    alertsData: any[] = cloneDeep(AlertsData.alertsData)

    weatherData: WeatherData = cloneDeep(presetWeatherData)

    siteConditions: SiteConditions = cloneDeep(presetSiteConditions)

    communicationTableData: CommunicationTable = cloneDeep(presetCommunicationSummaryData)

    inventoryTableData: InventoryTableSite[] = [] as InventoryTableSite[]

    carouselData: CarouselItem[] = cloneDeep(presetCarouselData);

    binGraphData: BinGraphData[] = [] as BinGraphData[];

    siteGraphData: SiteGraphData[] = [] as SiteGraphData[];

    automationDevicesData: AutomationDevicesData[] = [] as AutomationDevicesData[];

    siteStructures: SiteStructures = cloneDeep(presetSiteStructuresData);

    userStructures: SiteStructures[] = [] as SiteStructures[];

    lostDevicesData: LostDevice[] = [] as LostDevice[];

    highTemperatureData: HighTemperatureData[] = [] as HighTemperatureData[];

    playThroughMap = false;

    currentMarkerIndex = -1;

    dealerNotifications: DealerNotification[] = [] as DealerNotification[];

    constructor() {
        makeObservable(this, {
            error: observable,
            setError: action,
            success: observable,
            setSuccess: action,
            confirm: observable,
            setConfirm: action,
            awaitingUserData: observable,
            setAwaitingUserData: action,
            awaitingApi: observable,
            setAwaitingApi: action,
            presetBinsenseStore: action,
            dataModel: observable,
            setModelData: action,
            userData: observable,
            setUserData: action,
            dealerUserData: observable,
            setDealerUserData: action,
            deviceTableData: observable,
            setDeviceTableData: action,
            setNotificationPhoneNumbers: action,
            setNotificationEmails: action,
            storageListData: observable,
            setStorageListData: action,
            binNotesData: observable,
            setBinNotesData: action,
            serviceRecordsData: observable,
            setServiceRecordsData: action,
            binLogsData: observable,
            setBinLogsData: action,
            customerSiteData: observable,
            setCustomerSiteData: action,
            dealerReportsData: observable,
            setDealerReportsData: action,
            customerReportsData: observable,
            setCustomerReportsData: action,
            myUsersData: observable,
            setMyUsersData: action,
            differencesData: observable,
            setDifferencesData: action,
            commandsData: observable,
            setCommandsData: action,
            commandHistoryData: observable,
            setCommandHistoryData: action,
            commandCount: observable,
            setCommandCount: action,
            notesTableData: observable,
            setNotesTableData: action,
            customerUsersData: observable,
            setCustomerUsersData: action,
            associationsData: observable,
            setAssociationsData: action,
            cableAssociationsData: observable,
            setCableAssociationsData: action,
            fanAssociationsData: observable,
            setFanAssociationsData: action,
            automationAccessoriesData: observable,
            setAutomationAccessoriesData: action,
            playThroughMap: observable,
            setPlayThroughMap: action,
            togglePlayThroughMap: action,
            currentMarkerIndex: observable,
            setCurrentMarkerIndex: action,
            alertsData: observable,
            setAlertsData: action,
            weatherData: observable,
            setWeatherData: action,
            communicationTableData: observable,
            setCommunicationTableData: action,
            inventoryTableData: observable,
            setInventoryTableData: action,
            carouselData: observable,
            setCarouselData: action,
            binGraphData: observable,
            setBinGraphData: action,
            siteGraphData: observable,
            setSiteGraphData: action,
            automationDevicesData: observable,
            setAutomationDevicesData: action,
            siteStructures: observable,
            setSiteStructures: action,
            userStructures: observable,
            setUserStructures: action,
            pushUserStructures: action,
            lostDevicesData: observable,
            setLostDevicesData: action,
            highTemperatureData: observable,
            setHighTemperatureData: action,
            siteConditions: observable,
            setSiteConditions: action,
            setShowEmptyStructures: action,
            dealerNotifications: observable,
            setDealerNotifications: action,
        });
        this.initializeFromAsyncStorage();
    }

    setError = (error: string) => {
        this.error = error;
    }

    setSuccess = (success: { title: string, message: string }) => {
        this.success = success;
    }

    setConfirm = (confirm: {
        title: string,
        message: string,
        onConfirm: () => void,
    }) => {
        this.confirm = confirm;
    }

    setAwaitingUserData = (awaitingUserData: boolean) => {
        this.awaitingUserData = awaitingUserData;
    }

    setAwaitingApi = (awaitingApi: boolean) => {
        this.awaitingApi = awaitingApi;
    }

    setModelData = (modelData: DataModel) => {
        this.dataModel = modelData;
    }

    setUserData = (userData: UserData) => {
        this.userData = userData;
    }

    setDealerUserData = (dealerUserData: DealerUserData) => {
        this.dealerUserData = dealerUserData;
    }

    setDeviceTableData = (deviceTableData: DeviceSettingsStructure[]) => {
        this.deviceTableData = deviceTableData;
    }

    setNotificationPhoneNumbers = (phoneNotifications: NotificationPhoneNumber[]) => {
        this.userData.settings.notificationPhoneNumbers = phoneNotifications;
    }

    setNotificationEmails = (emailNotifications: NotificationEmail[]) => {
        this.userData.settings.notificationEmails = emailNotifications;
    }

    setStorageListData = (storageListData: StorageListBin[]) => {
        this.storageListData = storageListData;
    }

    setBinNotesData = (binNotesData: BinNote[]) => {
        this.binNotesData = binNotesData;
    }

    setServiceRecordsData = (serviceRecordsData: ServiceRecord[]) => {
        this.serviceRecordsData = serviceRecordsData;
    }

    setBinLogsData = (binLogsData: BinLog[]) => {
        this.binLogsData = binLogsData;
    }

    setCustomerSiteData = (customerSiteData: any) => {
        this.customerSiteData = customerSiteData;
    }

    setDealerReportsData = (reportsData: any) => {
        this.dealerReportsData = reportsData;
    }

    setCustomerReportsData = (reportsData: CustomerReportData[]) => {
        this.customerReportsData = reportsData;
    }

    setMyUsersData = (myUsersData: MyUsersTableData[]) => {
        this.myUsersData = myUsersData;
    }

    setDifferencesData = (differencesData: Difference[]) => {
        this.differencesData = differencesData;
    }

    setCommandsData = (commandsData: any) => {
        this.commandsData = commandsData;
    }

    setCommandHistoryData = (commandHistoryData: Command[]) => {
        this.commandHistoryData = commandHistoryData;
    }

    setCommandCount = (count: number) => {
        this.commandCount = count;
    }

    setNotesTableData = (notesData: NotesTableData[]) => {
        this.notesTableData = notesData;
    }

    setCustomerUsersData = (customerUsersData: any) => {
        this.customerUsersData = customerUsersData;
    }

    setAssociationsData = (associationsData: Association[]) => {
        this.associationsData = associationsData;
    }

    setCableAssociationsData = (cableAssociationsData: CableAssociation[]) => {
        this.cableAssociationsData = cableAssociationsData;
    }

    setFanAssociationsData = (fanAssociationsData: FanAssociation[]) => {
        this.fanAssociationsData = fanAssociationsData;
    }

    setAutomationAccessoriesData = (automationAccessoriesData: AutomationAccessory[]) => {
        this.automationAccessoriesData = automationAccessoriesData;
    }

    setAlertsData = (alertsData: any) => {
        this.alertsData = alertsData;
    }

    setWeatherData = (weatherData: WeatherData) => {
        this.weatherData = weatherData;
    }

    setCommunicationTableData = (communicationTableData: CommunicationTable) => {
        this.communicationTableData = communicationTableData;
    }

    setInventoryTableData = (inventoryTableData: InventoryTableSite[]) => {
        this.inventoryTableData = inventoryTableData;
    }

    setCarouselData = (carouselData: CarouselItem[]) => {
        this.carouselData = carouselData;
    }

    setBinGraphData = (binGraphData: BinGraphData[]) => {
        this.binGraphData = binGraphData;
    }

    setSiteGraphData = (siteGraphData: SiteGraphData[]) => {
        this.siteGraphData = siteGraphData;
    }

    setAutomationDevicesData = (automationDevicesData: AutomationDevicesData[]) => {
        this.automationDevicesData = automationDevicesData;
    }

    setSiteStructures = (siteStructures: SiteStructures) => {
        this.siteStructures = siteStructures;
    }

    setUserStructures = (userStructures: SiteStructures[]) => {
        this.userStructures = userStructures;
    }

    pushUserStructures = (userStructures: SiteStructures, clear?: boolean) => {
        if (clear) {
            this.userStructures = [] as SiteStructures[];
        }
        this.userStructures.push(userStructures);
    }

    setLostDevicesData = (lostDevicesData: LostDevice[]) => {
        this.lostDevicesData = lostDevicesData;
    }

    setHighTemperatureData = (highTemperatureData: HighTemperatureData[]) => {
        this.highTemperatureData = highTemperatureData;
    }

    setPlayThroughMap = (value: boolean, sync: boolean) => {
        this.playThroughMap = value;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_PLAY_THROUGH_MAP, String(value));
        }
    }

    togglePlayThroughMap = () => {
        this.currentMarkerIndex = !this.playThroughMap ? 0 : -1;
        this.setPlayThroughMap(!this.playThroughMap, true);
    }

    setCurrentMarkerIndex = (index: number) => {
        this.currentMarkerIndex = index;
    }

    presetBinsenseStore = () => {
        this.setModelData(presetModelData);
        this.setUserData(presetUserData);
        this.setDealerUserData(presetDealerUserModel);
        this.setSiteStructures(presetSiteStructuresData);
        this.setPlayThroughMap(false, true);
        this.setAwaitingUserData(true);
    }

    syncWithAsyncStorage = (key: string, value: string ) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    }

    initializeFromAsyncStorage = () => {
        const playThroughMapResult = global.localStorage.getItem(Constants.STORE_KEY_PLAY_THROUGH_MAP);
        if (playThroughMapResult !== null) {
            this.setPlayThroughMap(JSON.parse(playThroughMapResult) === 'true', false);
        }
    }

    setSiteConditions = (conditions: SiteConditions) => {
        this.siteConditions = conditions;
    }

    setShowEmptyStructures = (showEmptyStructures: boolean, selectedView: string) => {
        if (selectedView === Constants.CUSTOMER) {
            this.userData.settings.preferences.showEmptyStructures = showEmptyStructures;
        } else {
            this.dealerUserData.settings.preferences.showEmptyStructures = showEmptyStructures;
        }
    }

    setDealerNotifications = (value: DealerNotification[]) => {
        this.dealerNotifications = value;
    }
}

export default new BinsenseStore();
