/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

export default {
    HIDE_SELECTED_SITE_DRAWERS: [ 'Customer', 'Create Site', 'Users', 'Preferences' ],

    CALIAN_DEALER_ID: 1,

    WINDOW_WIDTHS: [ 1710, 1650, 1550, 1510, 1475, 1450, 1353, 1335, 1275, 1105, 1765, 1450, 1185, 1400, 1250, 1300 ],

    MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS: 50,
    MAX_TEMP_LOW_THRESHOLD_IN_CELSIUS: 50,
    MIN_TEMP_HIGH_THRESHOLD_IN_CELSIUS: -20,
    MIN_TEMP_LOW_THRESHOLD_IN_CELSIUS: -20,

    MILLISECONDS_IN_A_MINUTE: 60000,
    MILLISECONDS_IN_AN_HOUR: 3600000,
    MILLISECONDS_IN_A_DAY: 86400000,
    MILLISECONDS_IN_A_WEEK: 604800000,
    MILLISECONDS_IN_A_MONTH: 2592000000,
    SENSOR_CAUTION_THRESHOLD: 4,

    DEFAULT_LONGITUDE: -104.4,
    DEFAULT_LATITUDE: 50.4,
    DEFAULT_ZOOM: 3,

    DEFAULT_DATA_FETCH_INTERVAL: 34,

    ACCEPTED: 'Accepted',
    ACCOUNT_ACTIVATION_DATE: 'Account Activation Date',
    ACCOUNT_ADDRESS: 'Account Address',
    ACCOUNT_INFORMATION: 'Account Information',
    ACCOUNT_RENEWAL_DATE: 'Account Renewal Date',
    ACTIONS: 'Actions',
    ACTIVE: 'Active',
    ACTIVITY: 'Activity',
    ADD_AUTOMATION_HUB: 'Add a Bin-Sense Plus Automation Hub',
    ADD_DEVICES: 'Add Devices',
    ADD_NEW: 'Add New',
    ADD_NEW_BAG: 'Add New Bag',
    ADD_NEW_PILE: 'Add New Pile',
    ADD_STRUCTURES: 'Add Structures',
    ADD_USER: 'Add User',
    ADDRESS: 'Address',
    ADVANCED: 'Advanced',
    AERATION: 'Aeration',
    ALERTS: 'Alerts',
    ALERT_SETTINGS: 'Alert Settings',
    ASSOCIATED_MASTER: 'Associated Master',
    ASSOCIATION_LOGS: 'Association Logs',
    ASSOCIATIONS: 'Associations',
    AUTO_LEVEL: 'Auto Level',
    AUTO_LEVEL_FOOTNOTE: '*Auto Level settings will only be applied to structures monitored by Live or Plus systems.',
    AUTO_LEVEL_ON: 'Auto Level is on',
    AUTO_LEVEL_ON_INFO: 'Automatic level detection is turned on for this bin. ' +
        'Your changes to the grain level are saved but may be overwritten next time the cables are read. ' +
        'You can turn off Auto Level in the Settings tab for this bin.',
    AUTOMATION: 'Automation',
    AUTOMATION_ACCESSORIES: 'Automation Accessories',
    AUTOMATION_HUB: 'Automation Hub',
    AUTOMATION_REMOTE: 'Automation Remote',
    AUTOMATION_SETTINGS: 'Automation Settings',
    AVG_MOISTURE: 'Avg. Moisture',
    AVG_TEMP: 'Avg. Temp',

    BACK: 'Back',
    BAG: 'Bag',
    BAG_DETAILS: 'Bag Details',
    BAG_SETUP: 'Bag Setup',
    BALANCE_DUE_DATE: 'Payment Due Date',
    BATTERY_POWERED: 'Battery Powered',
    BILLING_ADDRESS: 'Billing Address',
    BILLING_AND_PAYMENT: 'Billing & Payment',
    BILLING_INFORMATION: 'Billing Information',
    BIN: 'Bin',
    BIN_DETAILS: 'Bin Details',
    BIN_MODEL: 'Bin Model',
    BIN_SETUP: 'Bin Setup',
    BUILDING: 'Building',
    BUILDING_DETAILS: 'Building Details',
    BUILDING_SETUP: 'Building Setup',
    BUSHELS: 'Bushels',
    BUSHEL_CAPACITY: 'Bushel Capacity',

    CABLE_DETAILS: 'Cable Details',
    CABLES: 'Cables',
    CABLE_SMART_ID: 'Cable Smart ID',
    CABLES_REPORTED: 'Cables Reported',
    CHANGE_PASSWORD: 'Change Password',
    CHANGE_USERNAME: 'Change Username',
    CHANNEL: 'Channel',
    CITY_TOWN: 'City/Town',
    CLOSE: 'Close',
    COMMAND: 'Command',
    COMMANDS: 'Commands',
    COMMODITY: 'Commodity',
    COMMUNICATION_LOST: 'Comm. Lost',
    COMMUNICATION_SUMMARY: 'Communication Summary',
    COMMUNICATION_SUMMARY_CONNECTED_CABLES: 'Communication Summary of Connected Cables',
    CONFIRMATION: 'Confirmation',
    CONTACT_DETAIL: 'Contact Detail',
    CONTENTS: 'Contents',
    COUNTRY: 'Country',
    CREATED_AT: 'Created At',
    CREATE_NEW_USER: 'Create New User',
    CREATE_SITE: 'Create Site',
    CURRENT_CABLE_ASSOCIATIONS: 'Current Cable Associations',
    CURRENT_FAN_ASSOCIATIONS: 'Current Fan Associations',
    CURRENT_OWING_BALANCE: 'Current Owing Balance',
    CURRENT_PLAN: 'Current Plan',
    CUSTOM : 'Custom',
    CUSTOMER : 'Customer',
    CUSTOMER_SERVICE_RECORDS: 'Customer Service Records',

    DASHBOARD: 'Dashboard',
    DATE: 'Date',
    DAYS_SINCE_LAST_CALL_IN: 'Days Since Last Call In',
    DEALER: 'Dealer',
    DEALER_NOTIFICATION_DUPLICATE: 'duplicateAdminContact',
    DEALER_NOTIFICATION_DUPLICATE_MESSAGE: 'The contact detail you have entered already exists for your dealer group.',
    DEALER_NOTIFICATION_OPT_IN: 'Do you want to start receiving text messages from Bin-Sense? Standard message and data rates may apply. ' +
        'Reply HELP for help, STOP to unsubscribe. You will receive messages regarding problems with the sensors, temperature alerts, ' +
        'and other important data points that can be configured from the Bin-Sense website and app. ' +
        'Message frequency varies based on the selection.',
    DEALER_NOTIFICATION_OPT_IN_ERROR: 'In order to add a new phone number to receive alerts, ' +
        'you will need to select the checkbox to opt in to our messaging services.',
    DEFAULT: 'Default',
    DELETE: 'Delete',
    DELETE_CUSTOMER: 'Delete Customer',
    DELETE_SITE: 'Delete Site',
    DELETE_USER: 'Delete User',
    DESCRIPTION: 'Description',
    DETAIL: 'Detail',
    DETAILS: 'Details',
    DEVICE: 'Device',
    DEVICES: 'Devices',
    DEVICE_SETTINGS: 'Device Settings',
    DEVICES_REPORTED: 'Devices Reported',
    DIAMETER: 'Diameter',
    DIFFERENCE: 'Difference',
    DIMENSIONS: 'Dimensions',
    DIRECT: 'Direct',
    DISABLED: 'Disabled',
    DISABLE_THIS_SENSOR: 'Disable this Sensor',
    DISABLE_THIS_SENSOR_INFO: 'Disabling this sensor will ignore all readings from this sensor.' +
        ' No alerts will be generated for this sensor, and it will not be considered during automatic level detection.' +
        '\n\nSensors should only be disabled if they are giving inaccurate readings.',
    DISPLAY: 'Display',

    EAVE_HEIGHT: 'Eave Height (ft)',
    EDIT: 'Edit',
    EDIT_ACCOUNT: 'Edit Account',
    EDIT_AUTOMATION_HUB: 'Edit Automation Master Hub',
    EDIT_CUSTOMER_INFORMATION: 'Edit Customer Information',
    EDIT_ORDER: 'Edit Order',
    EDIT_USER: 'Edit User',
    EMAIL: 'Email',
    ENABLE_THIS_SENSOR: 'Enable This Sensor',
    END_MOIST: 'End Moist.',
    END_TEMP: 'End Temp.',
    ENTER_SERIAL: 'Enter Serial',
    ENTER_SITE_NAME: 'Please enter a site name.',
    EXPAND: 'Expand',
    EXPORT: 'Export',
    EXPORT_TO_PDF: 'Export to PDF',
    EXPORT_TO_CSV: 'Export to CSV',
    EXTEND: 'Extend',
    EXTEND_DUE_DATE: 'Extend Due Date',
    EXTEND_DUE_DATE_MESSAGE: 'This button will extend the customer\'s due date by 14 days and unlock the customer\'s account immediately.',
    END_ASSOCIATION: 'End Association',
    END: 'End',

    FAHRENHEIT: 'Fahrenheit',
    FAN_CONTROL: 'Fan Control',
    FAN_CONTROLLER: 'Fan Controller',
    FAN_ID: 'Fan ID',
    FAN_DISPLAY_STATUS_OFF: 'Off',
    FAN_DISPLAY_STATUS_RUNNING: 'Running',
    FAN_DISPLAY_STATUS_RUNNING_MANUAL: 'Running (On-Site Startup)',
    FAN_STATUS: 'Fan Status',
    FAN_STATUS_MISSING: 'MISSING',
    FAN_STATUS_RUNNING: 'RUNNING',
    FAN_STATUS_STARTING: 'STARTING',
    FAN_STATUS_OFF: 'OFF',
    FILL_LEVEL: 'Fill Level',
    FIRMWARE_VERSION: 'Firmware Version',
    FIRST_NAME: 'First Name',

    GRAIN_TYPE: 'Grain Type',
    GRID: 'Grid',
    GROUP: 'Group',

    HARDWARE_FAILURES: 'HW Failures',
    HEIGHT: 'Height',
    HIGHEST_TEMP: 'Highest Temp',
    HIGH_TEMP_ALERTS: 'High Temp. Alerts',
    HOURS: 'Hours',

    INCREASE_SUBSCRIPTION: 'Increase Subscription',
    INSTALL_DATE: 'Install Date',
    INTRAGRAIN_DEALER: 'Intragrain',
    INVENTORY: 'Inventory',

    LAST_CABLE: 'Last Cable',
    LAST_CALL_IN: 'Last Call In',
    LAST_NAME: 'Last Name',
    LAST_VOLTAGE: 'Last Voltage',
    LENGTH: 'Length',
    LIST: 'List',
    LIVE: 'Live',

    MAIN: 'Main',
    MAKE: 'Make',
    MANAGE_BILLING: 'Manage Billing',
    MANAGE_SITE: 'Manage Site',
    MANUAL: 'Manual',
    MANUAL_MODE: 'Manual Mode',
    MAP: 'Map',
    MASTER: 'Master',
    MAX_TEMP: 'Max Temp',
    MEMBER_EMAIL: 'Member Email',
    MESSAGE: 'Message',
    MOBILE_NUMBER: 'Mobile Number',
    MOIST_CABLES: 'Moist. Cables',
    MOIST_CHANGE: 'Moist. Change',
    MOIST_CONTROL: 'Moisture Control',
    MOISTURE: 'Moisture',
    MY_CUSTOMERS: 'My Customers',
    MY_USERS: 'My Users',

    NA: 'N/A',
    NAME: 'Name',
    NATURAL_AIR_DRYING: 'Natural Air Drying',
    NEW: 'New',
    NEW_NOTE: 'New Note',
    NEW_PASSWORD: 'New Password',
    NEW_RECORD: 'New Record',
    NEW_USER: 'New User',
    NO: 'No',
    NO_DATA_TO_DISPLAY: 'No data to display',
    NO_DEALER: 'No Dealer',
    NO_READING: 'No Reading',
    NONE: 'none',
    NOTE: 'Note',
    NOTES: 'Notes',
    NOTIFICATIONS: 'Notifications',
    NOT_MONITORED: 'Not Monitored',
    NUMBER_OF_CABLES: '# of Cables',

    OFF: 'Off',
    OFFLINE: 'Offline',
    ON: 'On',
    ONE_DAY: '1 Day',
    ONE_MONTH: '1 Month',
    ONE_WEEK: '1 Week',
    ONLINE: 'Online',
    OPERATION_MODE_AERATION: 'aeration',
    OPERATION_MODE_MANUAL: 'manual',
    OPERATION_MODE_NATURAL_AIR_DRYING: 'natural_air_drying',
    OVERDUE_PAYMENT: 'Overdue Payment',
    OVERDUE_WARNING: 'You currently have an outstanding balance and are unable to manage your sites, structures, and ' +
        'devices.  Please make payment to regain this functionality.',

    PASSWORD: 'Password',
    PASSWORD_CONFIRMATION: 'Password Confirmation',
    PAY_NOW: 'Pay Now',
    PEAK_HEIGHT: 'Peak Height (ft)',
    PHONE: 'Phone',
    PILE_DETAILS: 'Pile Details',
    PILE_SETUP: 'Pile Setup',
    PLAYTHROUGH: 'Playthrough',
    PLENUM_SENSOR: 'Plenum Sensor',
    PLUGGED_IN: 'Plugged In',
    POSTAL_CODE_OR_ZIP_CODE: 'Postal Code or Zip Code',
    POWER_SOURCE: 'Power Source',
    PREFERENCES: 'Preferences',
    PREFERRED_TIMEZONE: 'Preferred Timezone',
    PROVINCE_OR_STATE: 'Province or State',
    PLUS: 'Plus',

    QUANTITY: 'Quantity',
    QUEUE_TIME: 'Queue Time',
    QUOTES: 'Quotes',

    RECEIVED_DATE: 'Received Date',
    REGISTRATION: 'Registration',
    REGISTER_NEW_USER: 'Register New User',
    REMOTE: 'Remote',
    REMOTE_UNIT: 'Remote Unit',
    REPLY: 'Reply',
    REPORTS: 'Reports',
    RESEND_TRANSFER_EMAIL: 'Resend Transfer Email',
    RESET_SITE_LOCATION: 'Reset Site Location',
    RESET_TERMS_OF_SERVICE: 'Reset Terms of Service',
    ROLE: 'Role',
    ROTATION: 'Rotation',
    ROW: 'Row',

    SATELLITE: 'Satellite',
    SAVE_CHANGES: 'Save Changes',
    SEARCH: 'Search',
    SELECT_DEALER: 'Select Dealer',
    SELECT_DEVICE: 'Select Device',
    SELECT_DEVICE_TYPE: 'Select Device Type',
    SELECT_SITE: 'Select Site',
    SELECT_USER: 'Select User',
    SEND: 'Send',
    SENT_TIME: 'Sent Time',
    SENSOR_COLOR_GREEN : '#1b820b',
    SENSOR_COLOR_GREY : '#929292',
    SENSOR_COLOR_RED : '#ff0000',
    SENSOR_COLOR_YELLOW : '#ffe600',
    SERIAL: 'Serial',
    SERIAL_NUMBER: 'Serial Number',
    SERVICE_RECORD: 'Service Record',
    SET_CABLE_COUNT: 'Set Cable Count',
    SET_FILL_LEVEL: 'Set Fill Level Here',
    SET_SENSOR_COVERED: 'Set Sensor Covered',
    SET_SENSOR_EXPOSED: 'Set Sensor Exposed',
    SET_SITE_LOCATION: 'Set Site Location',
    SETTINGS: 'Settings',
    SHAPE: 'Shape',
    SIDE_VIEW: 'Side View',
    SITE: 'Site',
    SITE_NAME: 'Site Name',
    SITE_NAME_IN_USE: 'Site name already in use.',
    SITE_REPORT: 'Site Report',
    SOLO: 'Solo',
    START: 'Start',
    START_MOIST: 'Start Moist.',
    START_TEMP: 'Start Temp.',
    STATUS: 'Status',
    STOP: 'Stop',
    STORAGE: 'Storage',
    STRUCTURE: 'Structure',
    STRUCTURE_TYPE: 'Structure Type',
    SWAP_SERIAL: 'Swap Serial',
    SYSTEM_THRESHOLD: 'Sys. Threshold',

    TABLE_VIEW: 'Table View',
    TEMP_CABLES: 'Temp. Cables',
    TEMP_CHANGE: 'Temp. Change',
    TEMP_CONTROL: 'Temperature Control',
    TEMPERATURE: 'Temperature',
    TERMS_OF_SERVICE: 'Terms of Service',
    THRESHOLDS: 'Thresholds',
    TITLE: 'Title',
    TOP_VIEW: 'Top View',
    TOTAL: 'Total',
    TOTAL_AUTOMATION_DEVICES: 'Total Bin-Sense Plus Devices',
    TOTAL_DEVICES: 'Total Devices',
    TOTAL_LIVE_DEVICES: 'Total Live Devices',
    TOTAL_SOLO_DEVICES: 'Total Solo Devices',
    TOWN_OR_CITY: 'Town or City',
    TRANSFER_ACCEPTED: 'Customer Accepted Transfer',
    TRANSFER_TO_CUSTOMER: 'Transfer To Customer',
    TRANSFER_TO_CUSTOMER_CONFIRM: 'Are you sure you want to transfer to the customer?',
    TYPE: 'Type',

    UNITS: 'Units',
    USER: 'User',
    USERNAME: 'Username',
    USER_PASSWORD: 'User Password',
    USERS: 'Users',
    USERS_DEALER: 'User\'s Dealer',

    VIEW_DEVICE: 'View Device',
    VOLTAGE: 'Voltage',
    VOLTAGE_ALERTS: 'Voltage Alerts',

    WAIVE_SUBSCRIPTION_OWING_BALANCE: 'Waive Subscription Owing Balance',
    WEEKLY_TEMP_RISE: 'Weekly Temp. Rise',
    WIDTH: 'Width',

    YES: 'Yes',

    SET_SENSOR_ONLY_THIS_SENSOR: 'Only This Sensor',
    SET_SENSOR_ALL_SENSORS_ON_THIS_BIN: 'All Sensors on this Bin',
    SET_SENSOR_ALL_SENSORS_ON_THIS_CABLE: 'All Sensors on this Cable',
    SET_SENSOR_AT_THIS_LEVEL_AND_ABOVE: 'Sensors at this level and above',
    SET_SENSOR_AT_THIS_LEVEL_AND_BELOW: 'Sensors at this level and below',
    STORE_KEY_PROTOCOL: '@BinsenseWebStore_protocol',
    STORE_KEY_IP_ADDRESS: '@BinsenseWebStore_ip_address',
    STORE_KEY_PORT: '@BinsenseWebStore_port',
    STORE_KEY_BASE_DIRECTORY: '@BinsenseWebStore_base_directory',
    STORE_KEY_EMAIL: '@BinsenseWebStore_email',
    STORE_KEY_TOKEN: '@BinsenseWebStore_token',
    STORE_KEY_UDD: '@BinsenseWebStore_udd',
    STORE_KEY_BIOMETRIC_AUTHENTICATION: '@BinsenseWebStore_biometric',
    STORE_KEY_DEVICE_ID: '@BinsenseWebStore_deviceID',
    STORE_KEY_LIGHT_MODE: '@BinsenseWebStore_lightMode',
    STORE_KEY_SELECTED_VIEW: '@BinsenseWebStore_selectedView',
    STORE_KEY_MAP_VIEW: '@BinsenseWebStore_mapView',
    STORE_KEY_SELECTED_SITE: '@BinsenseWebStore_selectedSite',
    STORE_KEY_SELECTED_DRAWER: '@BinsenseWebStore_selectedDrawer',
    STORE_KEY_VIEW_DEVICE_ID: '@BinsenseWebStore_viewDeviceId',
    STORE_KEY_PAGE_IDS: '@BinsenseWebStore_pageIds',
    STORE_KEY_PAGE_DATA: '@BinsenseWebStore_pageData',
    STORE_KEY_CURRENT_PAGE: '@BinsenseWebStore_currentPage',
    STORE_KEY_PAGE_QUEUE: '@BinsenseWebStore_pageQueue',
    STORE_KEY_MY_CUSTOMERS_SEARCH: '@BinsenseWebStore_myCustomersSearch',
    STORE_KEY_STORAGE_MODE: '@BinsenseWebStore_storageMode',
    STORE_KEY_PLAY_THROUGH_MAP: '@BinsenseWebStore_playThroughMap',
    STORE_KEY_SHOW_INTRO_MESSAGES: '@BinsenseWebStore_showIntroMessages',
    STORE_KEY_SHOW_WEATHER_MESSAGES: '@BinsenseWebStore_showWeatherMessages',
    STORE_KEY_SHOW_INVENTORY_MESSAGES: '@BinsenseWebStore_showInventoryMessages',
    STORE_KEY_SHOW_DIFFERENCES_MESSAGES: '@BinsenseWebStore_showDifferencesMessages',
    STORE_KEY_SHOW_AMBIENT_MESSAGES: '@BinsenseWebStore_showAmbientMessages',
    STORE_KEY_SHOW_PLENUM_CONDITIONS_MESSAGES: '@BinsenseWebStore_showPlenumConditionsMessages',
    STORE_KEY_SHOW_MANUAL_MESSAGES: '@BinsenseWebStore_showManualMessages',
    STORE_KEY_SHOW_TARGET_TEMP_MESSAGES: '@BinsenseWebStore_showTargetTempMessages',
    STORE_KEY_SHOW_ADVANCED_MESSAGES: '@BinsenseWebStore_showAdvancedMessages',
    STORE_KEY_SHOW_MIN_TEMP_MESSAGES: '@BinsenseWebStore_showMinTempMessages',
    STORE_KEY_SHOW_EMC_MOISTURE_MESSAGES: '@BinsenseWebStore_showEMCMoistureMessages',
    STORE_KEY_SHOW_TARGET_MOISTURE_MESSAGES: '@BinsenseWebStore_showTargetMoistureMessages',
    STORE_KEY_SHOW_EMC_RANGE_MESSAGES: '@BinsenseWebStore_showEMCRangeMessages',
    STORE_KEY_SHOW_AUTOMATION_HUB_MESSAGES: '@BinsenseWebStore_showAutomationHubMessages',
    STORE_KEY_SHOW_POWER_SOURCE_MESSAGES: '@BinsenseWebStore_showPowerSourceMessages',
    STORE_KEY_SHOW_NOT_MONITORED_MESSAGES: '@BinsenseWebStore_showNotMonitoredMessages',
    STORE_KEY_SHOW_DEFAULT_MESSAGES: '@BinsenseWebStore_showDefaultMessages',
    STORE_KEY_SHOW_COMMUNICATION_SUMMARY_MESSAGES: '@BinsenseWebStore_showCommunicationSummaryMessages',
    STORE_KEY_SHOW_DASHBOARD_MESSAGES: '@BinsenseWebStore_showDashboardMessages',
    STORE_KEY_SHOW_LIGHT_MODE_MESSAGES: '@BinsenseWebStore_showLightModeMessages',
    STORE_KEY_SHOW_EMPLOYEE_MESSAGES: '@BinsenseWebStore_showEmployeeMessages',
    STORE_KEY_SHOW_EQUIPMENT_MESSAGES: '@BinsenseWebStore_showEquipmentMessages',
    STORE_KEY_SHOW_REPORTS_MESSAGES: '@BinsenseWebStore_showReportsMessages',
    STORE_KEY_SHOW_ACCOUNT_PREFERENCES_MESSAGES: '@BinsenseWebStore_showAccountPreferencesMessages',
    STORE_KEY_SHOW_DEVICE_MESSAGES: '@BinsenseWebStore_showDeviceMessages',
    STORE_KEY_SHOW_ADD_EMPLOYEE_MESSAGES: '@BinsenseWebStore_showAddEmployeeMessages',
    STORE_KEY_SHOW_ADD_EQUIPMENT_MESSAGES: '@BinsenseWebStore_showAddEquipmentMessages',
    STORE_KEY_SHOW_ADD_DEVICE_MESSAGES: '@BinsenseWebStore_showAddDeviceMessages',
    STORE_KEY_SHOW_SITE_LOCATION_MESSAGES: '@BinsenseWebStore_showSiteLocationMessages',
    STORE_KEY_SHOW_TANK_DEVICE_MESSAGES: '@BinsenseWebStore_showTankDeviceMessages',
    STORE_KEY_BEGIN_TOOLTIPS: '@BinsenseWebStore_showBeginTooltips',
    STORE_KEY_DISMISSED_WELCOME: '@BinsenseWebStore_dismissedWelcome',
    STORE_KEY_SHOW_SATELLITE_VIEW_MESSAGE: '@BinsenseWebStore_showSatelliteViewMessage',
    STORE_KEY_REDIRECTED_TO_STRIPE: '@BinsenseWebStore_redirectedToStripe',
    STORE_KEY_SELECTED_CUSTOMER: '@BinsenseWebStore_selectedCustomer',

    MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiY2FsaWFuYWciLCJhIjoiY2w1bDl2ODJuMGhrZjNrbnd4NnFiNHo1dSJ9.X7qVEs8HDMPqocv5Uheu0Q',

    UNMONITORED: 'Unmonitored',
};
