/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {ReactNode} from 'react';

export enum Theme {
    dark = 'dark',
    light = 'light',
}

export enum XAxisType {
    dateTime = 'datetime',
    numeric = 'numeric',
    category = 'category',
}

export enum DistanceUnit {
    feet = 'ft',
    meters = 'm',
}

export enum MarkerTypes {
    bin = 'bin',
    grainPile = 'grainPile',
    grainBag = 'grainBag',
    automation = 'automation',
    building = 'building'
}

export enum GrainPileShape {
    circle = 'Circle',
    square = 'Square',
}

export enum BinType {
    flat = 'Flat',
    hopper = 'Hopper',
    bag = 'Bag',
}

export enum AutomationOperationModes {
    manual = 'manual',
    aeration = 'aeration',
    natural_air_drying = 'natural_air_drying',
}

export interface Account {
    accountEmail: string,
    address: Address,
    companyName: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    userName: string,
    billingAddress?: Address,
}

export interface ActionButton {
    id: string,
    onClick: () => void,
    icon: object,
    hoverText?: string,
    disabled?: boolean,
}

export interface Address {
    address: string,
    city: string,
    country: string,
    postalCode: string,
    province: string,
}

export interface AutomationDefaults {
    targetTemperature: string,
    targetMoisture: string,
    minimumAllowedTemperature: string,
    minimumTemperature: string,
    maximumTemperature: string,
    plenumRise: string
}

export interface Association {
    associationId: string,
    id: number,
    structure: string,
    serialNumber: string,
    status: string,
    firmwareVersion: number,
    channel: number,
    powerSource: string,
    fanControl: string,
    device: string,
    structId: number,
    type: string,
}

export interface AutomationAccessory {
    id: string,
    type: string,
    serial: string,
    selected: boolean,
}

export interface AutomationDevicesData {
    id: number,
    structure: string,
    remoteUnit: boolean,
    cables: boolean,
    fanController: boolean,
    plenumSensor: boolean,
}

export interface AutomationHubs {
    id: number,
    name: string,
    settings: Settings,
    capacity: number,
    longitude: number,
    latitude: number,
    latLongOffset: boolean,
    hardware: Hardware,
    status: BinStatus,
    sortOrder: number,
}

export interface AutomationSettings {
    structId: number,
    operationMode: AutomationOperationModes,
    targetMoisture: number,
    targetEmcRangeLow: number,
    targetEmcRangeHigh: number,
    targetTemperature: number,
    targetTemperatureRangeLow: number,
    targetTemperatureRangeHigh: number,
    plenumRise: number,
    plenumOffset: number,
    minimumAllowedTemperature: number,
    running: boolean,
}

export interface UserBalance {
    amount: number,
    dueDay: number,
    subscribedAutomation: number,
    subscribedMasters: number,
    subscribedSolos: number,
    currentPlanAmount: number,
    renewalDate: number,
}

export interface Bin {
    binType: string,
    capacity: number,
    diameter: number,
    eaveHeight: number | null,
    hardware: Hardware,
    id: number,
    latLongOffset: boolean,
    latitude: number,
    longitude: number,
    name: string,
    peakHeight: number | null,
    settings: Settings,
    status: BinStatus,
    topSvg: string,
    automationSettings: AutomationSettings | null | undefined;
    sortOrder: number,
}

export interface BinGraphData {
    alert: string,
    amb: number,
    avgmc: number,
    avgtmp: number,
    dt: number,
    fan1: null,
    fan2: null,
    fan3: null,
    fan4: null,
    productId: number,
    grainLevel: number,
    maxmc: number,
    maxtmp: number,
    minmc: number,
    mintmp: number,
    voltageAlert: string,
    fanOn: number,
    voltage: number,
    temperature: number,
    moisture: number,
}

export interface BinLog {
    id: number,
    type: string,
    logDate: number,
    title: string,
    log: string,
    structId: number,
}

export interface BinNote {
    dateAdded: number,
    id: number,
    note: string,
    structureId: number,
}

export interface BinStatus {
    alertStates: string[],
    avgMoisture: number,
    avgTemperature: number,
    lastReading: number,
    maxMoisture: number,
    maxTemperature: number,
    minMoisture: number,
    minTemperature: number,
    moistureTrend: string,
    percentFull: number,
    temperatureTrend: string,
    volume: number,
    plenumTemp: number,
    plenumRh: number,
    emc: number,
}

export interface Building {
    capacity: number,
    hardware: Hardware,
    height: number,
    id: number,
    latitude: number,
    latLongOffset: boolean,
    length: number,
    locationX: number,
    locationY: number,
    longitude: number,
    name: string,
    polygon: Polygon,
    rotation: number,
    settings: Settings,
    width: number,
    status: BinStatus,
    sortOrder: number,
}

export interface Cable {
    id: number,
    sensorCount: number,
    sensors: Sensor[],
    serial: string,
    smartId: number,
    status: CableStatus,
    type: string,
}

export interface CableAssociation {
    id: number,
    type: string,
    smartId: number,
    serial: string,
    selected: boolean,
}

export interface CableStatus {
    x: number,
}

export interface CarouselItem {
    type: string,
    url: string,
    title: string,
    subtext: string,
    src: string,
}

export interface Command {
    id: number,
    command: string,
    commandText: string,
    queueTime: number,
    reply: string,
    replyText: string,
    sentTime: number,
    serial: string,
    status: string,
}

export interface CommunicationTable {
    siteId: number,
    cableCommunication: CommunicationTableData,
    deviceCommunication: CommunicationTableData,
}

export interface CommunicationTableData {
    title: string,
    rows: TableRow[],
    columns: TableColumn[],
}

export interface Country {
    abbreviation: string,
    id: number,
    name: string,
    phoneCountryCode: string,
    regions: Region[],
}

export interface Customer {
    account: Account,
    id: number,
    sites: Site[],
    dealerId?: number,
    retailerHasAccess: boolean,
    temperatureUnits?: string,
    timeZone?: string,
}

export interface CustomerReportData {
    structure: string,
    tempCables: number,
    moistureCables: number,
    devices: StructureDevice[],
}

export interface DataModel {
    countries: Country[],
    dealers: Dealer[]
    grains: Grain[],
    serverCommandsGroupMap: ServerCommandsGroup[],
    timeZones: string[],
    automationDefaults: AutomationDefaults,
}

export interface Dealer {
    active: boolean,
    email: string,
    id: number,
    name: string,
    phone: string,
}

export interface DealerUser {
    id: number,
    emailAddress: string,
    username: string,
    phoneNumber: string,
    password: string,
    passwordVerify: string,
    firstName: string,
    lastName: string,
    dealerId: number,
}

export interface DealerUserData {
    id: number,
    account: Account,
    customers : Customer[],
    dealerGroups: Dealer[],
    dealerUsers: DealerUser[],
    generated: number,
    dealerId: number,
    settings: UserSettings,
}

export interface DeviceSettingsStructure {
    alerts: 'thresholds',
    fanControl: string,
    fanRunHours: number,
    lowTempThreshold: number | null,
    highTempThreshold: number | null,
    id: number,
    name: string,
    selected: boolean,
    typeKey: 'bins' | 'buildings' | 'grainBags',
    weeklyTempRise: number,
    sortOrder: number,
    showDevice: boolean,
    grainId: number,
    autoLevel: boolean,
    hardwareType: string,
}

export interface Difference {
    endMoisture: string,
    endTemp: string,
    level: string,
    moistureChange: string,
    startMoisture: string,
    startTemp: string,
    tempChange: string,
}

export interface Fan {
    id: number,
    localId: number | string,
    serial: number,
    status: FanStatus,
}

export interface FanAssociation {
    id: number,
    fanLocalId: number | string,
    fanId: number,
    selected: boolean,
}

export interface FanStatus {
    status: string,
    statusTime: number,
}

export interface Grain {
    bushelsPerTonne: number,
    cgc: boolean,
    id: number,
    name: string,
    minimumEmc: string,
    maximumEmc: string,
}

export interface GrainBag {
    capacity: number,
    hardware: Hardware,
    id: number,
    latLongOffset: boolean,
    latitude: number,
    length: number,
    width: number,
    longitude: number,
    name: string,
    rotation: number,
    settings: Settings,
    binType: string,
    diameter: number,
    eaveHeight: number,
    peakHeight: number,
    status: BinStatus,
    topSvg: string,
    automationSettings: AutomationSettings | null | undefined;
    sortOrder: number,
}

export interface Hardware {
    cables: Cable[],
    fans: Fan[],
    masterUnits: MasterUnit[],
    plenumSensor: PlenumSensor,
    remoteUnits: RemoteUnit[],
    soloUnits: SoloUnit[],
    weatherStation: WeatherStation,
}

export interface HighTemperatureData {
    id: number,
    user: string,
    name: string,
    structure: string,
    device: string,
    maxTemp: string,
    siteId: number,
    structureId: number,
}

export interface IGTResponse<Type> {
    data: Type,
    dataType: string,
    displayMessage: boolean,
    message: string,
    messageTitle?: string,
    status: string,
}

export interface InputFieldProps {
    value: string,
    setValue?: (newValue: any) => void,
    error?: string,
    setError?: (newError: string) => void,
    onChange?: () => void,
}

export interface InventoryStructure {
    id: number,
    site: string,
    bushels: number,
    minTemp: number,
    maxTemp: number,
    minMoisture: number,
    maxMoisture: number,
    commodity: string,
    percentFull: number,
}

export interface InventoryTableSite {
    id: number,
    site: string,
    bushels: number,
    structures: InventoryStructure[],
}

export interface LoginConfig {
    loginUrl: string,
    authUrl: string,
    tokenUrl: string,
    clientId: string,
}

export interface LostDevice {
    id: number,
    user: string,
    name: string,
    structure: string,
    device: string,
    lastCallIn: number,
    siteId: number,
    structureId: number,
}

export interface MasterUnit {
    dateAssociated: number,
    firmwareVersion: number,
    hardwareVersion: number,
    id: number,
    serial: string,
    siteId: number,
    status: UnitStatus,
    automation?: boolean,
    checkInTime?: Date,
}

export interface MLTag {
    structId: number,
    sensorId: number,
    start: number,
    end: number,
    observation: string,
    notes: string
}

export interface Model {
    diameter: number,
    eaveHeight: number,
    hopperAngle: number,
    hopperHeight: number,
    id: number,
    name: string,
    peakHeight: number,
    type: string,
}

export interface MyCustomersTableData {
    id: number,
    name: string,
    user: string,
    city: string,
    dealer: string,
    sites: {
        id: number,
        name: string,
        siteStatus: string,
    }[],
    retailerHasAccess: boolean,
}

export interface MyUsersTableData {
    id: number,
    email: string,
    name: string,
    dealer: string,
}

export interface NaturalAirDryingDefaults {
    targetMoisture: string,
    autoEmcRangeLowThreshold: string,
    autoEmcRangeHighThreshold: string,
    tempRangeLowThreshold: string,
    tempRangeHighThreshold: string,
    plenumRise: string,
}

export interface NewPassword {
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
}

export interface Note {
    date: Date,
    id: number,
    note: string,
    user: string,
}

export interface NotesTableData {
    date: number,
    id: number,
    note: string,
    structure: string,
    structureId: number,
}

export interface NotificationEmail {
    email: string,
    id: number,
}

export interface NotificationPhoneNumber {
    id: number,
    phoneNumber: string,
    receiveAssociationNotifications: boolean,
    receiveThresholdNotifications: boolean,
    receiveVoltageNotifications: boolean,
}

export interface Pile {
    id: number,
    name: string,
    rotation: number,
    settings: Settings,
    capacity: number,
    longitude: number,
    latitude: number,
    latLongOffset: boolean,
    hardware: Hardware,
    shape: string,
    diameter: number,
    width: number,
    quantity: number,
    status: BinStatus,
    sortOrder: number,
}

export interface PlenumSensor {
    dateAssociated: number,
}

export interface Polygon {
    polygonId: number,
    polygonType: string,
    siteId: number,
    vertices: PolygonVertex[],
}

export interface PolygonVertex {
    latitude: number,
    longitude: number,
    polygonId: number,
    vertexId: number,
    vertexIndex: number,
}

export interface Region {
    abbreviation: string,
    id: number,
    name: string,
}

export interface RemoteUnit {
    firmwareVersion: number,
    hardwareVersion: number,
    id: number,
    masterSerial: string,
    serial: string,
    status: UnitStatus,
    automation?: boolean,
}

export interface SelectOptions {
    id: string,
    title: string,
    value?: string,
}

export interface Sensor {
    CGCHigh: boolean,
    CGCLevel: number,
    CGCMedium: boolean,
    ROI: boolean,
    disabled: boolean,
    error: boolean,
    exposed: boolean,
    fan: boolean,
    id: number,
    idString?: string,
    index: number,
    moistureContent: number,
    rh: number,
    temperature: number,
    thresholdHigh: boolean,
    thresholdLow: boolean,
    thresholdSys: boolean,
    canConsiderForBinColor: boolean,
    sensorMuted: boolean,
}

export interface ServerCommand {
    arguments: string,
    command: string,
    commandDesc: string,
    commandName: string,
    groupName: string,
    id: number,
}

export interface ServerCommandsGroup {
    groupName: string,
    commands: ServerCommand[],
}

export interface ServiceRecord {
    id: number | null,
    notes: string,
    serviceDate: Date,
    userId: number,
}

export interface Settings {
    autoCapacity: boolean,
    autoExposeCoverSensors: boolean,
    autoVolume: boolean,
    exposedCoveredAlerts: boolean,
    productId: number,
    grainLevelOffset: number,
    hardwareType: string,
    highTemperatureThreshold: number,
    lowTemperatureThreshold: number,
    moistureOffset: number,
    temperatureMonitorType: string,
    voltageAlerts: boolean,
    weeklyTemperatureRiseMinimumTemperature: number,
    weeklyTemperatureRiseThreshold: number,
}

export interface Site {
    bins: Bin[],
    buildings: Building[],
    grainBags: GrainBag[],
    automationHubs: AutomationHubs[],
    id: number,
    latitude: number,
    longitude: number,
    name: string,
    structNames?: string[],
    ambientTemp?: number,
    humidity?: number,
    tempTrend?: string,
    humidityTrend?: string,
    automationRemotesCount?: number,
    dataGeneratedTime: Date,
    live?: boolean,
}

export interface SiteCommunicationSummary {
    siteId: number,
    cableCommunication: CommunicationTableData,
    deviceCommunication: CommunicationTableData,
}

export interface SiteStructures {
    id: number,
    automationHubs: AutomationHubs[],
    bins: Bin[],
    buildings: Building[],
    grainBags: GrainBag[],
    structNames: string[],
    dataGeneratedTime: Date,
}

export interface SoloUnit {
    firmwareVersion: number,
    hardwareVersion: number,
    serial: string,
    status: SoloUnitStatus,
}

export interface SoloUnitStatus {
    batteryLevel: number,
    cableTime: number,
    installDate: number,
    lastCallIn: number,
    voltage: number,
    voltageTime: number,
    reportingStatus: string,
}

export interface StorageListBin {
    id: number,
    name: string,
    fanStatus: string,
    fillLevel: number | string,
    lastReported: number | string,
    maxTemp: number | string,
    avgTemp: number | string,
    avgMoisture: number | string,
    status: BinStatus,
    sortOrder: number,
    grainType: string,
    type: string,
}

export interface StructureDevice {
    deviceType: string,
    serial: string,
    masterSerial?: string,
    firstAssociated: number,
    voltage: number,
    lastVoltage: number,
    lastCable: number,
    device: string,
    status: string,
    checkInTime?: Date,
    lowBattery: boolean
}

export interface TableColumn {
    header: string,
}

export interface TableCSVColumn {
    field: string,
    headerName: string,
}

export interface TableRow {
    header: string,
    values: TableRowValue[],
    id: number,
}

export interface TableRowValue {
    type: string,
    status: string,
    value: string,
}

export interface UnitStatus {
    acPower: boolean,
    batteryLevel: number,
    cableTime: number,
    firstAssociated: number,
    reportingStatus: string,
    voltage: number,
    voltageTime: number,
}

export interface UserData {
    account: Account,
    balance: UserBalance,
    bushelChange: number,
    capacityChange: number,
    generated: number,
    id: number,
    lockedOut: boolean,
    masterUnits: MasterUnit[],
    runningFansChange: number,
    settings: UserSettings,
    sites: Site[],
    totalBushels: number,
    totalCapacity: number,
    totalFansRunning: number,
}

export interface UserPreferences {
    areaUnits: string,
    distanceUnits: string,
    language: string,
    temperatureUnits: string,
    timeZone: string,
    volumeUnits: string,
    showEmptyStructures: boolean,
}

export interface UserSettings {
    allowDealerAccess: boolean,
    dealerId: number,
    notificationEmails: NotificationEmail[],
    notificationPhoneNumbers: NotificationPhoneNumber[],
    preferences: UserPreferences,
    preferredSiteId: number,
}

export interface WeatherData {
    currentTemperature: number,
    forecastedHighTemperature: number,
    forecastedLowTemperature: number,
    humidity: number,
    windSpeed: number,
    windGusts: number,
    POP: number,
}

export interface SiteConditions {
    ambientTemp: number | null,
    humidity: number | null,
    tempTrend: string | null,
    humidityTrend: string | null
}

export interface WeatherStation {
    dateAssociated: number,
}

export interface ValidateFieldProps {
    value: string,
    setError: (newError: string) => void,
}

export interface MarkerData {
    id: number,
    longitude: number,
    latitude: number,
    icon?: ReactNode,
    draggable?: boolean,
    minZoom?: number,
    maxZoom?: number,
    tooltip?: MarkerTooltipData,
    display?: MarkerDisplayData,
    siteTooltip?: SiteTooltipData,
    heatThresholdColor?: string,
    secondaryColor?: boolean,
    siteCount?: number,
}

export interface MarkerTooltipData {
    name: string,
    editable: boolean,
    displayData?: {
        status: string,
        maxTemp: string,
        maxMoisture: string,
        moistureTrend: string,
        tempTrend: string,
        level: string,
        fans: string,
        type: string,
        serial: string,
        contents: string,
    }
    automationData?: {
        status: string,
        ambientTemp: string,
        tempTrend: string,
        humidity: string,
        humidityTrend: string,
        serial: string,
        remotesCount: number,
    }
    structure?: Bin | GrainBag | Building
}

export interface MarkerDisplayData {
    type: MarkerTypes,
    grainBinData?: GrainBinData,
    grainPileData?: GrainPileData,
    grainBagData?: GrainBagData,
    buildingData?: BuildingData,
    automationHubData?: AutomationData,
}

export interface SiteTooltipData {
    name: string,
    customer: {
        id: number,
        firstName: string,
        lastName: string,
        email: string,
    },
    displayData: {
        deviceType: string,
        status: string,
    },
}

export interface SiteLocationData {
    id: number,
    name: string,
    longitude: number,
    latitude: number,
    zoom: number
}

export interface DistancePerPixel {
    mPerPx: number,
    ftPerPx: number,
}

export interface GrainPileData {
    associatedMaster: string,
    device: string,
    deviceType: string,
    diameter: number,
    name: string,
    powerSource: string,
    quantity: number,
    shape: GrainPileShape,
    sn: string[],
    unit: DistanceUnit,
}

export interface GrainBinData {
    name: string,
    type: BinType,
    peakHeight: number | null,
    eaveHeight: number | null,
    bushelCapacity?: number | null,
    device: string,
    deviceType: string,
    sn: string[],
    associatedMaster: string,
    diameter: number,
    unit: DistanceUnit,
    powerSource: string,
}

export interface GrainBagData {
    associatedMaster: string,
    device: string,
    deviceType: string,
    length: number,
    name: string,
    powerSource: string,
    quantity: number,
    rotation: number,
    sn: string[],
    unit: DistanceUnit,
    width: number,
}

export interface BuildingData {
    associatedMaster: string,
    device: string,
    deviceType: string,
    length: number,
    name: string,
    powerSource: string,
    rotation: number,
    sn: string[],
    unit: DistanceUnit,
    width: number,
    height: number,
}

export interface AutomationData {
    serial: string,
}

export interface StructIdSerials {
    id: number,
    sn: string[],
}

export type ButtonColors = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;

export interface SiteGraphData {
    dt: number,
    temperature: number,
    rh: number,
}

export interface DealerNotification {
    id: number,
    dealerId: number,
    contactMethod: string,
    phoneNumber: string,
    sendMasterTexts: boolean,
    sendSystemAlerts: boolean,
    sendVoltageAlerts: boolean,
    optedIn: boolean,
}