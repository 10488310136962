/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import Constants from '../../helper/Constants';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import Colors from '../../helper/Colors';
import {Button} from '@mui/material';
import {useMemo} from 'react';
import getIndex from '../../helper/functions/getIndex';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';

interface Props {
    page: string,
    disabled?: boolean,
}

const crumb = observer((props: Props) => {
    const MY_CUSTOMERS_USER_ROW = props.page === Constants.CUSTOMER_SERVICE_RECORDS ||
        props.page === Constants.EDIT_ACCOUNT || props.page === Constants.PAY_NOW ||
        props.page === Constants.BILLING_AND_PAYMENT || props.page === Constants.MANAGE_BILLING;
    const MY_CUSTOMERS_SITE_ROW = WebStore.selectedDrawer === Constants.MY_CUSTOMERS && (
        props.page === Constants.DEVICE_SETTINGS ||
        props.page === Constants.SITE_REPORT ||
        props.page === Constants.COMMANDS ||
        props.page === Constants.ACTIVITY ||
        props.page === Constants.STORAGE
    );
    const ASSOCIATIONS_ROW = props.page === Constants.ADVANCED || props.page === Constants.ACTIVITY;

    const pageIndex1 = useMemo(() => getIndex(WebStore.pageIds[0], WebStore.pageData[0]),
        [ WebStore.pageIds, WebStore.pageData ]);

    const pageIndex2 = useMemo(() => pageIndex1 >= 0 ? getIndex(WebStore.pageIds[1], WebStore.pageData[1]) : -1,
        [ WebStore.pageIds, WebStore.pageData, pageIndex1 ]);

    const pageIndex3 = useMemo(() => pageIndex2 >= 0 ? getIndex(WebStore.pageIds[2], WebStore.pageData[2]) : -1,
        [ WebStore.pageIds, WebStore.pageData ]);

    const onCrumbClick = () => {
        if (WebStore.selectedDrawer === Constants.CREATE_SITE) {
            WebStore.setIsLoseChangesConfirmationOpen(true);
        }
        else {
            while (WebStore.currentPage !== props.page) {
                WebStore.goToPreviousPage();
            }
        }
    };

    const renderLabel = useMemo(() => {
        if (MY_CUSTOMERS_USER_ROW && pageIndex1 !== -1) {
            return  `${ WebStore.pageData[0][pageIndex1].user } ${ props.page }`;
        }
        if (MY_CUSTOMERS_SITE_ROW && pageIndex2 !== -1) {
            return `${ WebStore.pageData[1][pageIndex2].name } ${ props.page }`;
        }
        if (ASSOCIATIONS_ROW && ((WebStore.selectedView === Constants.DEALER && pageIndex3 !== -1) ||
            (WebStore.selectedView === Constants.CUSTOMER && pageIndex1 !== -1))) {
            const data = WebStore.selectedView === Constants.DEALER
                ? WebStore.pageData[2][pageIndex3] : WebStore.pageData[0][pageIndex1];
            return `${ data.structure } ${ props.page }`;
        }
        return props.page;
    }, [ WebStore.pageData, WebStore.pageIds, WebStore.selectedView, props.page ]);

    return props.page !== WebStore.currentPage ? (
        <Button
            {...getTestID(`${ props.page }_breadcrumb`)}
            onClick={onCrumbClick}
            sx={{ color: WebStore.lightMode ? Colors.text : Colors.textLight }}
            disabled={props.disabled}
        >
            {renderLabel}
        </Button>
    ) : (
        <Typography
            {...getTestID(`${ WebStore.currentPage }_breadcrumb`)}
            color="primary"
            sx={{
                fontWeight: 'bold',
                fontSize: 14,
                marginLeft: '16px',
            }}
        >
            {renderLabel}
        </Typography>
    );
});

export default crumb;
