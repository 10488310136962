/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import BinsenseStore from '../../../../stores/BinsenseStore';
import {Download} from '@mui/icons-material';
import getStorageListBins from '../../../../helper/functions/getStorageListBins';
import exportTableToCSV from '../../../../helper/functions/exportTableToCSV';
import StorageListTable from './StorageListTable';
import {Customer, Site} from '../../../../types/components/ApiTypes';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {Button, styled} from '@mui/material';
import filterEmptyStructures from '../../../../helper/functions/filterEmptyStructures';
import EditBinOrderModal from '../../sortOrder/EditBinOrderModal';
import getTestID from '../../../../helper/functions/getTestID';
import WebStore from '../../../../stores/WebStore';
import { getBalanceByUsername } from '../../../../controllers/apiCalls/get/getBalance';
import getUserStructures from '../../../../controllers/apiCalls/get/getUserStructures';

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '0px',
});

const Row = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
    paddingBottom: '12px',
    paddingRight: '20px',
});

interface Props {
    site: Site,
    search: string,
    showEmpty: boolean,
    setShowEmpty: (includeEmpty: boolean) => void,
    user?: Customer,
}

const StorageList = observer((props: Props) => {
    const rows = useMemo(() => filterEmptyStructures(BinsenseStore.storageListData, props.search, props.showEmpty),
        [ BinsenseStore.storageListData, props.search, props.showEmpty ]);

    useEffect(() => BinsenseStore.setStorageListData(getStorageListBins(props.site)), [ props.site ]);

    useEffect(() => {
        if (WebStore.selectedView === Constants.DEALER && props.user?.account.userName) {
            getUserStructures(undefined, props.user.account.userName);
            getBalanceByUsername(undefined, props.user.account.userName);
        }
    }, [ props.user ]);

    const [ editBinOrderModalOpen, setEditBinOrderModalOpen ] = useState(false);

    const columns = [
        {
            field: 'name',
            headerName: 'Structure',
        },
        {
            field: 'fanStatus',
            headerName: 'Fan Status',
        },
        {
            field: 'maxTemp',
            headerName: 'Highest Temp',
        },
        {
            field: 'grainType',
            headerName: 'Grain Type',
        },
        {
            field: 'avgTemp',
            headerName: 'Avg. Temp',
        },
        {
            field: 'avgMoisture',
            headerName: 'Avg. Moisture',
        },
        {
            field: 'fillLevel',
            headerName: 'Fill Level',
        },
    ];

    const disableEditOrderButton = props.site.id > 0 ? false : true;

    return (
        <div>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <Row>
                    <Button
                        {...getTestID('edit_bin_order_button')}
                        onClick={() => setEditBinOrderModalOpen(true)}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                        disabled={disableEditOrderButton}
                    >
                        {Constants.EDIT_ORDER}
                    </Button>
                    <Button
                        {...getTestID('storage_list_export_button')}
                        onClick={() => exportTableToCSV(columns, rows, 'device_storage_data')}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                        startIcon={<Download/>}
                    >
                        {Constants.EXPORT}
                    </Button>
                </Row>
                ) : (
                    <Row>
                        <DashboardSkeleton
                            height={40}
                            width='100px'
                            style={{ marginRight: '8px' }}
                        />
                        <DashboardSkeleton
                            height={40}
                            width='100px'
                            style={{ marginRight: '8px' }}
                        />
                    </Row>
            )}
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <StorageListTable
                    rows={rows}
                    site={props.site}
                />
            ) : (
                <SkeletonRoot>
                    <DashboardSkeleton height={600}/>
                </SkeletonRoot>
            )}
            {editBinOrderModalOpen && (
                <EditBinOrderModal
                    close={() => setEditBinOrderModalOpen(false)}
                    site={props.site}
                />
            )}
        </div>
    );
});

export default StorageList;
