import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';
import Constants from '../../../helper/Constants';
import WebStore from '../../../stores/WebStore';
import { cloneDeep } from 'lodash';

const putUserSettings = (enqueueSnackbar: any, id: number, settings: any) => {
    callPutAPI(`userSettings/${ id }`,
        settings,
        (response) => {
            if (response === 'OK') {
                if (WebStore.selectedView.toLowerCase() === Constants.CUSTOMER.toLowerCase()) {
                    updateData(enqueueSnackbar);
                }
                else {
                    if (settings.customer?.account?.billingAddress) {
                        const selectedCustomer = cloneDeep(WebStore.selectedCustomer);
                        selectedCustomer.account.billingAddress = settings.customer.account.billingAddress;
                        WebStore.setSelectedCustomer(selectedCustomer, true);
                    }
                    updateData(enqueueSnackbar);
                }
            }
        },
        enqueueSnackbar,
        'User settings updated successfully'
    );
};

export default putUserSettings;