/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import MyCustomersMainPage from '../myCustomers/MyCustomersMainPage';
import WebStore from '../../../stores/WebStore';
import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import EditAccountPage from '../myCustomers/EditAccountPage';
import MyCustomerCommandsPage from '../myCustomers/commandsPage/MyCustomerCommandsPage';
import ViewDevicePage from '../../customer/storage/viewDevicePage/ViewDevicePage';
import StorageMainPage from '../../customer/storage/StorageMainPage';
import AdvancedAssociationsPage from '../myCustomers/associationsPage/AdvancedAssociationsPage';
import ReportsLogsPage from '../../customer/reports/logs/ReportsLogsPage';
import {useEffect, useMemo} from 'react';
import getIndex from '../../../helper/functions/getIndex';
import BinsenseStore from '../../../stores/BinsenseStore';
import getNewSite from '../../../helper/functions/getNewSite';
import CustomerServiceRecordsPage from '../myCustomers/customerServiceRecordsPage/CustomerServiceRecordsPage';
import ReportsMainPage from '../../customer/reports/ReportsMainPage';
import getNewBin from '../../../helper/functions/getNewBin';
import getNewUser from '../../../helper/functions/getNewUser';
import getSiteStructures from '../../../controllers/apiCalls/get/getSiteStructures';
import {useSnackbar} from 'notistack';
import PayNowPanel from '../../customer/billingAndPayment/PayNowPanel';
import DeviceSettingsMainPage from '../../customer/deviceSettings/DeviceSettingsMainPage';
import checkForLatestData from '../../../controllers/apiCalls/get/checkForLatestData';
import BillingAndPayment from '../../customer/contentPanels/BillingAndPayment';
import ManageBillingPanel from '../../customer/billingAndPayment/ManageBillingPanel';
import getCustomerBalance from '../../../controllers/apiCalls/get/getCustomerBalance';
import getCustomerBillingAddress from '../../../controllers/apiCalls/get/getCustomerBillingAddress';
import getSiteConditions from '../../../controllers/apiCalls/get/getSiteConditions';

const DealerMyCustomers = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const user = useMemo(() => {
        const index = getIndex(WebStore.pageIds[0], BinsenseStore.dealerUserData.customers);
        return index !== -1 ? BinsenseStore.dealerUserData.customers[index] : getNewUser();
    }, [ BinsenseStore.dealerUserData.customers, WebStore.pageIds[0] ]);

    useEffect(() => {
        if (user.id >= 0) {
            getCustomerBalance(enqueueSnackbar, user);
            getCustomerBillingAddress(enqueueSnackbar, user);
        }
    }, [ user.id ]);

    const siteIndex = useMemo(() => {
        if (WebStore.pageIds[1] !== -1) {
            return getIndex(WebStore.pageIds[1], user.sites);
        }
        return -1;
    }, [ user, WebStore.pageIds[1] ]);

    const siteId = useMemo(() => siteIndex !== -1 ? user.sites[siteIndex].id : -1, [ user, siteIndex ]);

    useEffect(() => {
        if (siteId !== -1) {
            getSiteStructures(enqueueSnackbar, siteId);
            getSiteConditions(enqueueSnackbar, siteId);
        }
    }, [ siteId, BinsenseStore.dealerUserData ]);

    const site = useMemo(() => {
        if (siteIndex !== -1) {
            const siteConditions = {
                ambientTemp: BinsenseStore.siteConditions.ambientTemp ?? undefined,
                humidity: BinsenseStore.siteConditions.humidity ?? undefined,
                tempTrend: BinsenseStore.siteConditions.tempTrend ?? undefined,
                humidityTrend: BinsenseStore.siteConditions.humidityTrend ?? undefined,
            };
            return {
                ...user.sites[siteIndex],
                ...BinsenseStore.siteStructures,
                ...siteConditions,
            };
        }
        return getNewSite();
    }, [ siteIndex, BinsenseStore.siteStructures, BinsenseStore.siteConditions ]);

    const checkForLatest = () => {
        const storeSiteStruct = BinsenseStore.siteStructures;
        if (storeSiteStruct.id !== -1) {
            checkForLatestData(null, storeSiteStruct.id, storeSiteStruct.dataGeneratedTime);
        }
    };

    let checkForLatestDataInterval = Constants.DEFAULT_DATA_FETCH_INTERVAL * 60 * 1000; // converting mins to millis

    try {
        if (window.globalConfig.checkForLatestDataInterval)
        checkForLatestDataInterval = window.globalConfig.checkForLatestDataInterval * 60 * 1000; // converting mins to millis
    } catch (e) {}

    useEffect(() => {
        const interval = setInterval(() => checkForLatest(), checkForLatestDataInterval);
        return () => clearInterval(interval);
    }, []);

    const structure = useMemo(() => {
        if (WebStore.pageIds[2] !== -1) {
            const key = [ 'bins', 'grainBags' ];
            for (let i = 0; i < key.length; i++) {
                const index = getIndex(WebStore.pageIds[2], site[key[i]]);
                if (index !== -1) {
                    return site[key[i]][index];
                }
            }
        }
        return getNewBin();
    }, [ site, WebStore.pageIds[2] ]);

    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return <MyCustomersMainPage/>;
            case Constants.DEVICE_SETTINGS:
                return (
                    <DeviceSettingsMainPage
                        site={site}
                        showDevice={user.retailerHasAccess}
                    />
                );
            case Constants.EDIT_ACCOUNT:
                return <EditAccountPage user={user}/>;
            case Constants.SITE_REPORT:
                return (
                    <ReportsMainPage
                        user={user}
                        site={site}
                    />
                );
            case Constants.ACTIVITY:
                return (
                    <ReportsLogsPage
                        site={site}
                        structureId={structure.id !== -1 ? structure.id : undefined}
                    />
                );
            case Constants.COMMANDS:
                return <MyCustomerCommandsPage site={site}/>;
            case Constants.STORAGE:
                return (
                    <StorageMainPage
                        site={site}
                        user={user}
                    />
                );
            case Constants.VIEW_DEVICE:
                return <ViewDevicePage site={site}/>;
            case Constants.ADVANCED:
                return <AdvancedAssociationsPage structure={structure}/>;
            case Constants.CUSTOMER_SERVICE_RECORDS:
                return <CustomerServiceRecordsPage user={user}/>;
            case Constants.PAY_NOW:
                return <PayNowPanel user={user}/>;
            case Constants.BILLING_AND_PAYMENT:
                return <BillingAndPayment/>;
            case Constants.MANAGE_BILLING:
                return <ManageBillingPanel user={user}/>;
            default:
                return <div/>;
        }
    };

    return (
        // !BinsenseStore.awaitingUserData condition is applicable only for PAY_NOW
        <ContentPanel>
            <>
                {((WebStore.currentPage === Constants.PAY_NOW && !BinsenseStore.awaitingUserData) ||
                    WebStore.currentPage !== Constants.PAY_NOW) &&
                    <>
                        {renderContentPanel()}
                    </>
                }
            </>
        </ContentPanel>
    );
});

export default DealerMyCustomers;
