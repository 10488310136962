/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../helper/Constants';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import {useMemo} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridPaper from '../../common/displayTable/DisplayGridPaper';
import {observer} from 'mobx-react-lite';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import CustomerPhoneNotificationsRow from './CustomerPhoneNotificationsRow';
import CustomerEmailNotificationsRow from './CustomerEmailNotificationsRow';

const CustomerNotificationsTable = observer(() => {
    const phoneData = useMemo(() => BinsenseStore.userData.settings.notificationPhoneNumbers,
        [ BinsenseStore.userData.settings.notificationPhoneNumbers ]);
    const emailData = useMemo(() => BinsenseStore.userData.settings.notificationEmails,
        [ BinsenseStore.userData.settings.notificationEmails ]);

    const renderGridItem = (label: string | number, id: string) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={6}
            header
        />
    );

    return (
        <div>
            <DisplayGridPaper>
                <DisplayGridHeaderRow>
                    {renderGridItem(Constants.DETAIL, 'detail')}
                    {renderGridItem(Constants.ACTIONS, 'actions_header')}
                </DisplayGridHeaderRow>
                {phoneData.map(data => (
                    <CustomerPhoneNotificationsRow
                        data={data}
                        key={`${ data.id }_phone_row`}
                    />
                ))}
                {emailData.map(data => (
                    <CustomerEmailNotificationsRow
                        data={data}
                        key={`${ data.id }_email_row`}
                    />
                ))}
            </DisplayGridPaper>
        </div>
    );
});

export default CustomerNotificationsTable;
