/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import Drawer from '../../drawer/Drawer';
import Constants from '../../../helper/Constants';
import Banner from '../../banner/Banner';
import WebStore from '../../../stores/WebStore';
import ConfirmModal from '../../common/modal/ConfirmModal';
import BinsenseStore from '../../../stores/BinsenseStore';
import Notes from '../contentPanels/Notes';
import Preferences from '../contentPanels/Preferences';
import Users from '../contentPanels/Users';
import Inventory from '../contentPanels/Inventory';
import StorageDrawer from '../contentPanels/Storage';
import Dashboard from '../contentPanels/Dashboard';
import getUserModel from '../../../controllers/apiCalls/get/getUserModel';
import GeneralDrawer from '../../drawer/lists/GeneralDrawer';
import Reports from '../contentPanels/Reports';
import Alerts from '../contentPanels/Alerts';
import DeviceSettings from '../contentPanels/DeviceSettings';
import ManagementDrawer from '../../drawer/lists/ManagementDrawer';
import CreateSite from '../../dealer/contentPanels/CreateSite';
import getCarouselItems from '../../../controllers/apiCalls/get/getCarouselItems';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import SetSiteLocationModal from '../../common/SetSiteLocationModal';
import getSite from '../../../helper/functions/getSite';
import AddNew from '../contentPanels/AddNew';
import getDataModel from '../../../controllers/apiCalls/get/getDataModel';
import BillingAndPayment from '../contentPanels/BillingAndPayment';
import OverduePaymentModal from '../billingAndPayment/OverduePaymentModal';
import WelcomeModal from '../dashboard/WelcomeModal';
import checkForUpdate from '../../../controllers/apiCalls/get/checkForUpdate';
import PayNowPanel from '../billingAndPayment/PayNowPanel';
import ManageBillingPanel from '../billingAndPayment/ManageBillingPanel';
import checkForLatestData from '../../../controllers/apiCalls/get/checkForLatestData';

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    // @ts-ignore
    backgroundColor: theme.palette.primary.background,
    height: '100vh',
    width: '100%',
    flexDirection: 'row',
}));

const Container = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const MainCustomer = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ siteLocationNotSet, setSiteLocationNotSet ] = useState(false);
    const [ overduePaymentModalOpen, setOverduePaymentModalOpen ] = useState(false);

    const checkForLatest = () => {
        const index = getSelectedSiteIndex();
        if (index > -1) {
            const site = getSite(index);
            checkForLatestData(null, site.id, site.dataGeneratedTime);
        }
    };

    let checkForLatestDataInterval = Constants.DEFAULT_DATA_FETCH_INTERVAL * 60 * 1000; // converting mins to millis

    try {
        if (window.globalConfig.checkForLatestDataInterval)
        checkForLatestDataInterval = window.globalConfig.checkForLatestDataInterval * 60 * 1000; // converting mins to millis
    } catch (e) {}

    useEffect(() => {
        const interval = setInterval(() => checkForLatest(), checkForLatestDataInterval);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getDataModel(enqueueSnackbar);
        getCarouselItems(enqueueSnackbar);
        getUserModel(enqueueSnackbar);
        const interval = setInterval(() => checkForUpdate(enqueueSnackbar, BinsenseStore.userData.generated), 1800000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (WebStore.selectedSiteId > -1) {
            const index = getSelectedSiteIndex();
            const site = getSite(index);
            if (site.latitude === null || site.longitude === null ||
                (site.id > 0 && (site.latitude === 0 || site.longitude === 0))) {
                setSiteLocationNotSet(true);
            }
        }
    }, [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    useEffect(() => {
        if (WebStore.overDuePayment !== overduePaymentModalOpen) {
            setOverduePaymentModalOpen(WebStore.overDuePayment);
        }
    }, [ WebStore.overDuePayment ]);

    const renderContentPanel = () => {
        switch (WebStore.selectedDrawer) {
            case Constants.DASHBOARD:
                return <Dashboard/>;
            case Constants.STORAGE:
                return <StorageDrawer/>;
            case Constants.REPORTS:
                return <Reports/>;
            case Constants.INVENTORY:
                return <Inventory/>;
            case Constants.NOTES:
                return <Notes/>;
            case Constants.USERS:
                return <Users/>;
            case Constants.CREATE_SITE:
                return <CreateSite isCustomer/>;
            case Constants.ADD_NEW:
                return <AddNew/>;
            case Constants.DEVICE_SETTINGS:
                return <DeviceSettings/>;
            case Constants.PREFERENCES:
                return <Preferences/>;
            case Constants.BILLING_AND_PAYMENT:
                return <BillingAndPayment/>;
            case Constants.PAY_NOW:
                return <PayNowPanel/>;
            case Constants.MANAGE_BILLING:
                return <ManageBillingPanel/>;
            case Constants.ALERTS:
                return <Alerts/>;
            default:
                return <div/>;
        }
    };

    return (
        <Root>
            <Drawer
                disableButtons={WebStore.overDuePayment}
                generalList={GeneralDrawer}
                managementList={ManagementDrawer}
            />
            <Container>
                <Banner/>
                {renderContentPanel()}
                {(BinsenseStore.confirm.title !== '' && BinsenseStore.confirm.message !== '') && <ConfirmModal/>}
            </Container>
            {overduePaymentModalOpen && (
                <OverduePaymentModal setModalOpen={setOverduePaymentModalOpen}/>
            )}
            {WebStore.newUser && !WebStore.overDuePayment && (
                <WelcomeModal
                    open
                    onClick={() => {
                        WebStore.setNewUser(false);
                        WebStore.setSelectedDrawer(Constants.CREATE_SITE, true);
                    }}
                />
            )}
            {siteLocationNotSet && !WebStore.overDuePayment && !WebStore.newUser && (
                <SetSiteLocationModal
                    open
                    close={() => setSiteLocationNotSet(false)}
                    site={WebStore.selectedSiteId > -1 ? getSite(getSelectedSiteIndex()) : undefined}
                    showMessage
                />
            )}
        </Root>
    );

});

export default MainCustomer;
