/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {styled} from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import TextFieldInput from '../../common/input/TextFieldInput';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import getIndex from '../../../helper/functions/getIndex';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import isEmailValid from '../../../helper/validator/isEmailValid';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    editId: number,
}

function EditEmailModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();

    const data = useMemo(() => BinsenseStore.userData.settings.notificationEmails,
        [ BinsenseStore.userData ]);
    const editIndex = useMemo(() => props.editId ? getIndex(props.editId, data) : -1, [ data, props.editId ]);

    const [ email, setEmail ] = useState(editIndex > -1 ? data[editIndex].email : '');
    const [ emailError, setEmailError ] = useState('');
    const initData = data[editIndex].email;

    const isValid = () => {
        let error = isEmailValid(email);
        if (error === '' && initData != email && data && data.some(item => item.email === email)) {
            error = 'The email address you are trying to add already exists on your account';
        }
        if (error !== '') {
            setEmailError(error);
        }
        return error === '';
    };

    const submit = () => {
        if (isValid()) {
            const settings = {
                notificationEmails: {
                    id: props.editId,
                    email,
                },
            };
            putUserSettings(enqueueSnackbar, BinsenseStore.userData.id, settings);
            props.close();
        }
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title="Edit Email"
        >
            <Container>
                <TextFieldInput
                    id="new_contact_email"
                    label={Constants.EMAIL}
                    field={{
                        value: email,
                        setValue: setEmail,
                        error: emailError,
                        setError: setEmailError,
                    }}
                />
            </Container>
        </BaseModal>
    );
}

export default EditEmailModal;
