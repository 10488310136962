/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Constants from '../../../../../helper/Constants';
import {Bin, Building, GrainBag} from '../../../../../types/components/ApiTypes';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Typography, styled} from '@mui/material';
import DeviceSensorTable from './DeviceSensorTable';
import BinImageSVG from '../../grid/BinImageSVG';
import WebStore from '../../../../../stores/WebStore';
import postUpdateStructureSensors from '../../../../../controllers/apiCalls/post/postUpdateStructureSensors';
import { useSnackbar } from 'notistack';
import getTestID from '../../../../../helper/functions/getTestID';
import BaseModal from '../../../../common/modal/BaseModal';
import { Info, Warning } from '@mui/icons-material';
import updateData from '../../../../../helper/functions/updateData';

interface Props {
    view: string,
    bin: Bin | GrainBag | Building,
    showSensorButtons?: boolean,
}

const Root = styled('div')({
    width: '100%',
    marginBottom: '16px',
});

const Container = styled('div')({
    width: '400px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // currently the bin image is not centered in the paper, it's slightly aligned to the left
    // temporary fix so that the buttons look aligned with the bin image
    marginLeft: '-20px',
});

const ColumnButton = styled('div')({
    justifyContent: 'center',
    alignItems: 'center',
});

const DeviceBinViewBody = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ selectedSensor, setSelectedSensor ] = useState({
        sensorId: '-1',
        cableId: -1,
        sensorExposed: false,
        sensorDisabled: false,
        sensorPosition: -1,
    });
    const [ showSensorButtons, setShowSensorButtons ] = useState(false);
    const [ showDisableSensorModal, setShowDisableSensorModal ] = useState(false);
    const [ showAutoLevelModal, setShowAutoLevelModal ] = useState(false);

    const handleSensorSelect = useCallback((event) => {
        const sensorId = event.detail.sensorId;
        setSelectedSensor(event.detail);

        if (sensorId !== '-1' && WebStore.countClicksOnSameSensorId % 2 === 0 &&
                props.bin.settings.hardwareType.toLowerCase() !== Constants.DIRECT.toLowerCase() &&
                props.showSensorButtons) {

            setShowSensorButtons(true);

        } else {
            setShowSensorButtons(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('onSensorSelected', handleSensorSelect);
        return () => {
            window.removeEventListener('onSensorSelected', handleSensorSelect);
        };

    }, [ handleSensorSelect ]);

    const onExposedButtonClick = async () => {
        WebStore.setPreviousSensorId('-1');
        if (selectedSensor.sensorId !== '-1') {
            if (selectedSensor.sensorExposed) {
                // set selected sensor and any below it (on that cable) to covered
                const body = {
                    structId: props.bin.id,
                    sensorId: Number(selectedSensor.sensorId),
                    sensorIdString: selectedSensor.sensorId,
                    setIsCovered: true,
                    setEntireCable: false,
                    setSensorsThisLevelAndAbove: false,
                    setSensorsThisLevelAndBelow: true,
                    setAllSensors: false,
                    excludeThisLevel: false,
                    onlySetCurrentCable: true,
                };
                postUpdateStructureSensors(enqueueSnackbar, body, props.bin, setShowAutoLevelModal);

            } else {
                // set selected sensor and any sensor above it (on that cable) to exposed
                const body = {
                    structId: props.bin.id,
                    sensorId: Number(selectedSensor.sensorId),
                    sensorIdString: selectedSensor.sensorId,
                    setIsCovered: false,
                    setEntireCable: false,
                    setSensorsThisLevelAndAbove: true,
                    setSensorsThisLevelAndBelow: false,
                    setAllSensors: false,
                    excludeThisLevel: false,
                    onlySetCurrentCable: true,
                };
                postUpdateStructureSensors(enqueueSnackbar, body, props.bin, setShowAutoLevelModal);
            }
        }
    };

    const onFillButtonClick = async () => {
        WebStore.setPreviousSensorId('-1');
        if (selectedSensor.sensorId !== '-1') {
            // set selected sensor and all sensors on other cables at the same level to the covered state,
            // and all sensors above that level to the exposed state.
            if (selectedSensor.sensorExposed) {
                const body = {
                    structId: props.bin.id,
                    sensorId: Number(selectedSensor.sensorId),
                    sensorIdString: selectedSensor.sensorId,
                    setIsCovered: true,
                    setEntireCable: false,
                    setSensorsThisLevelAndAbove: false,
                    setSensorsThisLevelAndBelow: true,
                    setAllSensors: false,
                    excludeThisLevel: false,
                };
                postUpdateStructureSensors(enqueueSnackbar, body, props.bin, setShowAutoLevelModal);

            } else {
                const body = {
                    structId: props.bin.id,
                    sensorId: Number(selectedSensor.sensorId),
                    sensorIdString: selectedSensor.sensorId,
                    setIsCovered: false,
                    setEntireCable: false,
                    setSensorsThisLevelAndAbove: true,
                    setSensorsThisLevelAndBelow: false,
                    setAllSensors: false,
                    excludeThisLevel: true,
                };
                postUpdateStructureSensors(enqueueSnackbar, body, props.bin, setShowAutoLevelModal);
            }
        }
    };

    const onDisableButtonClick = () => {
        WebStore.setPreviousSensorId('-1');
        if (selectedSensor.sensorId !== '-1') {
            if (selectedSensor.sensorDisabled) {
                // Set Enable
                const body = {
                    structId: props.bin.id,
                    sensorId: Number(selectedSensor.sensorId),
                    sensorIdString: selectedSensor.sensorId,
                    setIsDisabled: false,
                };
                postUpdateStructureSensors(enqueueSnackbar, body);

            } else {
                setShowDisableSensorModal(true);
            }
        }
    };

    const disableSensor = (months: number | null) => {
        if (selectedSensor.sensorId !== '-1' && !selectedSensor.sensorDisabled) {
            const body = {
                structId: props.bin.id,
                sensorId: Number(selectedSensor.sensorId),
                sensorIdString: selectedSensor.sensorId,
                setIsDisabled: true,
                numberOfMonthsBeingDisabled: months ?? 0,
            };
            postUpdateStructureSensors(enqueueSnackbar, body);
        }
    };

    const resetMouseClickedForSensor = () => {
        window.resetMouseClickedForSensor();
    };

    const component = useMemo(() => {
        if (props.view === Constants.TABLE_VIEW) {
            resetMouseClickedForSensor();
            WebStore.setPreviousSensorId('-1');
            setShowSensorButtons(false);
            return (
                <DeviceSensorTable
                    cables={props.bin.hardware?.cables ? props.bin.hardware.cables : []}
                    highTemperatureThreshold={props.bin.settings.highTemperatureThreshold}
                    lowTemperatureThreshold={props.bin.settings.lowTemperatureThreshold}
                />
            );
        }
        if (props.view === Constants.SIDE_VIEW) {
            return (
                <BinImageSVG
                    id={String(props.bin.id)}
                    structure={props.bin}
                />
            );
        }
        return <div/>;
    }, [ props.view, props.bin ]);

    const renderShowDisableSensorModal = () => showDisableSensorModal &&
        (
            <BaseModal
                id='disable_sensor_modal'
                open
                close={() => setShowDisableSensorModal(false)}
                title={Constants.DISABLE_THIS_SENSOR}
                titleObject={<Info color="primary"/>}
                hideButtons
            >
                <Container>
                    <Typography
                        {...getTestID('disable_this_sensor_info')}
                        style={{
                            whiteSpace: 'pre-line',
                            paddingBottom: '16px',
                        }}
                        display='block'
                    >
                        {Constants.DISABLE_THIS_SENSOR_INFO}
                    </Typography>
                    <ColumnButton>
                        <Button
                            {...getTestID('disable_sensor_one_month_button')}
                            onClick={() => disableSensor(1)}
                            variant='contained'
                            style={{
                                marginTop: '8px',
                                width: '50%',
                            }}
                        >
                            Disable for 1 month
                        </Button>
                    </ColumnButton>
                    <ColumnButton>
                        <Button
                            {...getTestID('disable_sensor_six_month_button')}
                            onClick={() => disableSensor(6)}
                            variant='contained'
                            style={{
                                marginTop: '8px',
                                width: '50%',
                            }}
                        >
                            Disable for 6 months
                        </Button>
                    </ColumnButton>
                    <ColumnButton>
                        <Button
                            {...getTestID('disable_sensor_indefinitely_button')}
                            onClick={() => disableSensor(null)}
                            variant='contained'
                            style={{
                                marginTop: '8px',
                                width: '50%',
                            }}
                        >
                            Disable indefinitely
                        </Button>
                    </ColumnButton>
                    <ColumnButton>
                        <Button
                            {...getTestID('disable_this_sensor_button')}
                            onClick={() => setShowDisableSensorModal(false)}
                            variant='text'
                            color='secondary'
                            style={{
                                marginTop: '8px',
                                width: '50%',
                            }}
                        >
                            Cancel
                        </Button>
                    </ColumnButton>
                </Container>
            </BaseModal>
        );

    const onCloseAutoLevelModal = () => {
        updateData(enqueueSnackbar);
        setShowAutoLevelModal(false);
    };

    return (
        <Root>
            {component}
            <div>
                {showSensorButtons &&
                    <div>
                        <Row>
                            <Button
                                {...getTestID('set_sensor_exposed_button')}
                                onClick={() => onExposedButtonClick()}
                                variant='contained'
                                style={{
                                    marginRight: '20px',
                                }}
                            >
                                {selectedSensor?.sensorExposed ? Constants.SET_SENSOR_COVERED : Constants.SET_SENSOR_EXPOSED}
                            </Button>
                            <Button
                                {...getTestID('set_fill_level_button')}
                                onClick={() => onFillButtonClick()}
                                variant='contained'
                            >
                                {Constants.SET_FILL_LEVEL}
                            </Button>
                        </Row>
                        <Row>
                            <Button
                                {...getTestID('disable_this_sensor_button')}
                                onClick={() => onDisableButtonClick()}
                                variant='text'
                                color='secondary'
                                style={{ marginTop: '8px' }}
                            >
                                {selectedSensor?.sensorDisabled ? Constants.ENABLE_THIS_SENSOR : Constants.DISABLE_THIS_SENSOR}
                            </Button>
                        </Row>
                    </div>
                }
            </div>
            {showAutoLevelModal &&
                <BaseModal
                    open={true}
                    id='auto_level_warning_modal'
                    close={onCloseAutoLevelModal}
                    title={Constants.AUTO_LEVEL_ON}
                    titleObject={<Warning color="primary"/>}
                    hideButtons
                >
                    <Container>
                        <Typography
                            style={{
                                marginTop: '8px',
                                marginBottom: '8x',
                                textAlign: 'center',
                            }}
                        >
                            {Constants.AUTO_LEVEL_ON_INFO}
                        </Typography>
                        <Button
                            {...getTestID('ok_button')}
                            onClick={() => onCloseAutoLevelModal()}
                            variant='contained'
                            style={{
                                marginTop: '16px',
                                width: '50%',
                                alignSelf: 'center',
                            }}
                        >
                            Ok
                        </Button>
                    </Container>
                </BaseModal>
            }
            {renderShowDisableSensorModal()}
        </Root>
    );
});

export default DeviceBinViewBody;
