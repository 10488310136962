/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Description, Group, Payment} from '@mui/icons-material';
import Constants from '../../../helper/Constants';
import WebStore from '../../../stores/WebStore';
import BinsenseStore from '../../../stores/BinsenseStore';
import replaceSpaces from '../../../helper/functions/replaceSpaces';
import {observer} from 'mobx-react-lite';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';

interface Props {
    data: any,
}

const MyCustomersTableRowSummary = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const renderGridLabel = (name: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={`${ name }_cell`}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const onIconClick = (page: string) => {
        WebStore.setPageIds([ props.data.id, -1, -1 ], true);
        WebStore.goToNewPage(page);
        WebStore.setPageData([ BinsenseStore.customerSiteData, null, null ], true);
    };

    const idStarter = replaceSpaces(props.data.user);

    return (
        <div style={{ width: '100%' }}>
            <DisplayGridContent key={`${ idStarter }_customer`}>
                {renderGridLabel(`${ idStarter }_user`, props.data.user, largeScreen ? 3.5 : 3, largeScreen ? 25 : 14)}
                {renderGridLabel(`${ idStarter }_name`, props.data.name, 2.5, largeScreen ? 20 : 12)}
                {renderGridLabel(`${ idStarter }_city`, props.data.city, 3, largeScreen ? 22 : 14)}
                <DisplayGridActions
                    buttons={[
                        {
                            id: `${ idStarter }_payment_button`,
                            onClick: () => onIconClick(Constants.BILLING_AND_PAYMENT),
                            icon: <Payment/>,
                            hoverText: Constants.BILLING_AND_PAYMENT,
                        },
                        {
                            id: `${ idStarter }_edit_account_button`,
                            onClick: () => onIconClick(Constants.EDIT_ACCOUNT),
                            icon: <Group/>,
                            hoverText: Constants.EDIT_ACCOUNT,
                        },
                        {
                            id: `${ idStarter }_customer_service_records_button`,
                            onClick: () => onIconClick(Constants.CUSTOMER_SERVICE_RECORDS),
                            icon: <Description/>,
                            hoverText: Constants.CUSTOMER_SERVICE_RECORDS,
                        },
                    ]}
                    xs={largeScreen ? 3 : 3.5}
                    key={`${ props.data.id }_actions_cell`}
                    id={idStarter}
                />
            </DisplayGridContent>
        </div>
    );
});

export default MyCustomersTableRowSummary;
