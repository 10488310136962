/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import uuid from 'react-uuid';
import Constants from '../../../helper/Constants';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Delete, Edit} from '@mui/icons-material';
import EditPhoneModal from './EditPhoneModal';
import {NotificationPhoneNumber} from '../../../types/components/ApiTypes';
import deleteNotificationPhone from '../../../controllers/apiCalls/delete/deleteNotificationPhone';
import {useSnackbar} from 'notistack';

interface Props {
    data: NotificationPhoneNumber,
}

const CustomerPhoneNotificationsRow = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [ editOpen, setEditOpen ] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState(false);

    const deleteNumber = () => {
        deleteNotificationPhone(enqueueSnackbar, props.data.id);
    };

    return (
        <DisplayGridContent key={uuid()}>
            <DisplayGridLabel
                id={`${ props.data.id }_phone_number`}
                label={props.data.phoneNumber}
                xs={6}
            />
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.data.id }_edit_button`,
                        onClick: () => setEditOpen(true),
                        icon: <Edit/>,
                        hoverText: Constants.EDIT,
                    },
                    {
                        id: `${ props.data.id }_delete_button`,
                        onClick: () => setDeleteOpen(true),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={6}
                id={`${ props.data.id }_actions`}
            />
            {editOpen && (
                <EditPhoneModal
                    id="edit_contact_detail_modal"
                    open
                    close={() => setEditOpen(false)}
                    editId={props.data.id}
                />
            )}
            {deleteOpen && (
                <ConfirmationModal
                    id='delete_number_confirmation'
                    open
                    close={() => setDeleteOpen(false)}
                    submit={deleteNumber}
                    title="Delete Phone Number"
                    message="Are you sure you want to delete this number? Notifications will no longer
                    be sent to this number."
                />
            )}
        </DisplayGridContent>
    );
});

export default CustomerPhoneNotificationsRow;
