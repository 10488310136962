import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import UIStateStore from '../../../stores/UIStateStore';
import WebStore from '../../../stores/WebStore';
import { UserData } from '../../../types/components/ApiTypes';
import callAPI from '../../callAPI';

export const getBalanceByUsername = (enqueueSnackbar: any, user: string) => {
    const customers = BinsenseStore.dealerUserData.customers;
    const userIndex = customers.findIndex(customer => (customer.account.accountEmail === user || customer.account.userName === user));
    const userId: number = BinsenseStore.dealerUserData.customers[userIndex].id;
    setTimeout(() => {
        callAPI(`balance/${ userId }`,
            (response) => {
                const data: UserData = WebStore.selectedView.toLowerCase() === Constants.CUSTOMER.toLowerCase()
                ? BinsenseStore.userData : WebStore.selectedCustomer;
                data.balance = response;
                WebStore.setSelectedCustomer(data, true);
            },
            enqueueSnackbar,
            false, true
        );
    }, 3000);
};

export const getBalance = (enqueueSnackbar: any, userId: number, setLoading: any, redirectedToStripe: boolean,
                    associationsChanged: boolean) => {
    callAPI(`balance/${ userId }`,
        (response) => {
            const data = WebStore.selectedView.toLowerCase() === Constants.CUSTOMER.toLowerCase()
                        ? BinsenseStore.userData : WebStore.selectedCustomer;

            const amountNotChanged = redirectedToStripe && response.amount === data.balance.amount;
            const numberOfDevicesNotChanged = associationsChanged &&
                response.subscribedAutomation === data.balance.subscribedAutomation &&
                response.subscribedMasters === data.balance.subscribedMasters &&
                response.subscribedSolos === data.balance.subscribedSolos;

            if (amountNotChanged || numberOfDevicesNotChanged) {
                // likely that the balance table was not updated yet
                // fetch the balance again after 3 seconds
                setTimeout(() => {
                    callAPI(`balance/${ userId }`,
                        (newResponse) => {
                            data.balance = newResponse;
                            if (WebStore.selectedView.toLowerCase() === Constants.CUSTOMER.toLowerCase()) {
                                BinsenseStore.setUserData(data);
                            }
                            else
                            {
                                WebStore.setSelectedCustomer(data, true);
                            }
                            setLoading(false);
                        },
                        enqueueSnackbar
                    );
                }, 3000);
            }
            else
            {
                data.balance = response;
                if (WebStore.selectedView.toLowerCase() === Constants.CUSTOMER.toLowerCase()) {
                    BinsenseStore.setUserData(data);
                }
                else
                {
                    WebStore.setSelectedCustomer(data, true);
                }
                setLoading(false);
            }

            // reset state to false
            if (redirectedToStripe) {
                UIStateStore.setRedirectedToStripe(false);
            }
            if (associationsChanged) {
                WebStore.setAssociationsChanged(false);
            }
        },
        enqueueSnackbar
    );
};