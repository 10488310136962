/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import getTestID from '../../../helper/functions/getTestID';
import DisplayGridItem from './DisplayGridItem';
import TypographyWithLengthLimit from '../typography/TypographyWithLengthLimit';

interface Props {
    id: string,
    label: string | number,
    header?: boolean,
    xs: number,
    textAlign?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined,
    fontSize?: number,
    lengthLimit?: number,
}

const DisplayGridLabel = (props: Props) => (
    <DisplayGridItem
        xs={props.xs}
        header={props.header}
    >
        <TypographyWithLengthLimit
            {...getTestID(props.id)}
            lengthLimit={props.lengthLimit}
            fontWeight="bold"
            fontSize={props.fontSize}
            textAlign={props.textAlign ? props.textAlign : 'left'}
        >
            {props.label}
        </TypographyWithLengthLimit>
    </DisplayGridItem>
);

export default DisplayGridLabel;
