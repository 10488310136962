/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import {styled} from '@mui/material';
import Constants from '../../../helper/Constants';
import ToggleButtonInput from '../../common/input/ToggleButtonInput';
import StorageList from './list/StorageList';
import StorageGrid from './grid/StorageGrid';
import StorageMap from './map/StorageMap';
import WebStore from '../../../stores/WebStore';
import {observer} from 'mobx-react-lite';
import {AutomationHubs, Customer, Site} from '../../../types/components/ApiTypes';
import TextFieldInput from '../../common/input/TextFieldInput';
import InputAdornment from '@mui/material/InputAdornment';
import {FilterList} from '@mui/icons-material';
import filterEmptyStructures from '../../../helper/functions/filterEmptyStructures';
import BinsenseStore from '../../../stores/BinsenseStore';
import filterByName from '../../../helper/functions/filterByName';

const Row = styled('div')({
    marginLeft: '25px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    site: Site,
    user?: Customer,
}

const StorageMainPage = observer((props: Props) => {
    const [ search, setSearch ] = useState('');
    const [ searchError, setSearchError ] = useState('');
    const [ includeEmpty, setIncludeEmpty ] = useState(WebStore.selectedView === Constants.DEALER
        ? BinsenseStore.dealerUserData.settings.preferences.showEmptyStructures
        : BinsenseStore.userData.settings.preferences.showEmptyStructures);

    useEffect(() => {
        BinsenseStore.setShowEmptyStructures(includeEmpty, WebStore.selectedView);
    }, [ includeEmpty ]);

    useEffect(() => {
        if (WebStore.selectedView === Constants.DEALER) {
            setIncludeEmpty(BinsenseStore.dealerUserData.settings.preferences.showEmptyStructures);
        } else {
            setIncludeEmpty(BinsenseStore.userData.settings.preferences.showEmptyStructures);
        }
    }, [ BinsenseStore.dealerUserData.settings.preferences.showEmptyStructures,
        BinsenseStore.userData.settings.preferences.showEmptyStructures ]);

    const mode = useMemo(() => WebStore.storageMode, [ WebStore.storageMode ]);

    const setMode = (newValue: string) => WebStore.setStorageMode(newValue, true);

    const filteredStructs = useMemo(() => {
        const bins = JSON.parse(JSON.stringify(props.site.bins));
        const bags = JSON.parse(JSON.stringify(props.site.grainBags));
        const buildings = JSON.parse(JSON.stringify(props.site.buildings));
        const keyArray = search.split(' ');
        let allStructures = [ ...bins, ...bags, ...buildings ];
        keyArray.forEach(key => allStructures = filterEmptyStructures(allStructures, key, includeEmpty));

        let sortIndexAvailable = false;
        allStructures.forEach(obj => {
            if (obj.sortOrder > 0) {
                sortIndexAvailable = true;
            }
        });

        if (sortIndexAvailable) {
            return allStructures.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            return allStructures;
        }
    }, [ props.site, search, includeEmpty ]);

    const automationHubs: AutomationHubs[] = useMemo(() => {
        let hubs = JSON.parse(JSON.stringify(props.site.automationHubs));
        const keyArray = search.split(' ');
        keyArray.forEach(key => hubs = filterByName(hubs, key));
        return hubs;
    }, [ props.site, search ]);

    const renderView = () => {
        switch (mode) {
            case Constants.GRID:
                return (
                    <StorageGrid
                        site={props.site}
                        structures={filteredStructs}
                        hubs={automationHubs}
                    />
                );
            case Constants.LIST:
                return (
                    <StorageList
                        site={props.site}
                        search={search}
                        showEmpty={includeEmpty}
                        setShowEmpty={setIncludeEmpty}
                        user={props.user}
                    />
                );
            case Constants.MAP:
                return (
                    <StorageMap
                        site={props.site}
                        user={props.user}
                        search={search}
                    />
                );
            default:
                return (<div/>);
        }
    };

    return (
        <div>
            <Row>
                <TextFieldInput
                    id="map_search_field"
                    label={Constants.SEARCH}
                    field={{
                        value: search,
                        setValue: setSearch,
                        error: searchError,
                        setError: setSearchError,
                    }}
                    textFieldProps={{
                        InputProps: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FilterList/>
                                </InputAdornment>
                            ),
                        },
                    }}
                    style={{
                        width: '400px',
                        marginRight: '16px',
                        marginBottom: '0px',
                    }}
                />
                <ToggleButtonInput
                    value={mode}
                    setValue={setMode}
                    options={[
                        {
                            id: 'grid_option',
                            title: Constants.GRID,
                        },
                        {
                            id: 'list_option',
                            title: Constants.LIST,
                        },
                        {
                            id: 'map_option',
                            title: Constants.MAP,
                        },
                    ]}
                    disabled={WebStore.editingMap}
                />
                {(mode == Constants.GRID || mode == Constants.LIST) &&
                <div style={{display:'inline',
                    marginLeft:'15px',
                }}
                >
                </div>
            }
            </Row>
            {renderView()}
        </div>
    );
});

export default StorageMainPage;
