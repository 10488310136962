/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import TitlePaper from '../../../../common/TitlePaper';
import GridTextFieldInput from '../../../../common/input/GridTextFieldInput';
import {Grid, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import Constants from '../../../../../helper/Constants';
import putBinSettings from '../../../../../controllers/apiCalls/put/putBinSettings';
import {useSnackbar} from 'notistack';
import getTempUnit from '../../../../../helper/functions/getTempUnit';
import InfoTooltip from '../../../../common/InfoTooltip';
import TooltipStore from '../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../helper/TooltipMessages';
import { displayTemp, fToC, cToF } from '../../../../../helper/functions/functions';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    bin: any,
}

const TemperatureSettingsPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ notificationType, setNotificationType ] = useState(Constants.THRESHOLDS);
    const [ highThreshold, setHighThreshold ] = useState(props.bin.settings.highTemperatureThreshold
        ? displayTemp(getTempUnit() === 'F', props.bin.settings.highTemperatureThreshold) : '');
    const [ highThresholdError, setHighThresholdError ] = useState('');
    const [ lowThresholdError, setLowThresholdError ] = useState('');
    const [ lowThreshold, setLowThreshold ] = useState(props.bin.settings.lowTemperatureThreshold
        ? displayTemp(getTempUnit() === 'F', props.bin.settings.lowTemperatureThreshold) : '');
    const [ weeklyTempRise, setWeeklyTempRise ] = useState(props.bin.settings.weeklyTemperatureRiseThreshold
        ? displayTemp(getTempUnit() === 'F', props.bin.settings.weeklyTemperatureRiseThreshold, true) : '');
    const [ weeklyTempRiseError, setWeeklyTempRiseError ] = useState('');
    const maxHighThreshold = getTempUnit() === 'F' ? cToF(Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS)
        : Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS;
    const isDirect = props.bin.settings.hardwareType.toLowerCase() === Constants.DIRECT.toLowerCase();
    const maxLowThreshold = getTempUnit() === 'F' ? cToF(Constants.MAX_TEMP_LOW_THRESHOLD_IN_CELSIUS)
        : Constants.MAX_TEMP_LOW_THRESHOLD_IN_CELSIUS;
    const minHighThreshold = getTempUnit() === 'F' ? cToF(Constants.MIN_TEMP_HIGH_THRESHOLD_IN_CELSIUS)
        : Constants.MIN_TEMP_HIGH_THRESHOLD_IN_CELSIUS;
    const minLowThreshold = getTempUnit() === 'F' ? cToF(Constants.MIN_TEMP_LOW_THRESHOLD_IN_CELSIUS)
        : Constants.MIN_TEMP_LOW_THRESHOLD_IN_CELSIUS;

    const isValid = () => {
        if ((highThreshold !== '' && Number(highThreshold) < minHighThreshold) ||
            (highThreshold !== '' && Number(highThreshold) > maxHighThreshold) ||
            (lowThreshold !== '' && Number(lowThreshold) > maxLowThreshold) ||
            (lowThreshold !== '' && Number(lowThreshold) < minLowThreshold) ||
            (lowThreshold !== '' && Number(lowThreshold) >= Number(highThreshold)) ||
            (!isDirect && weeklyTempRise === '') ||
            highThreshold === ''
        ) {
            return false;
        }
        return true;
    };

    const setErrors = () => {
        if (!isDirect && weeklyTempRise === '') {
            setWeeklyTempRiseError('Please enter a Weekly Temp. Rise.');
        }
        if (highThreshold === '') {
            setHighThresholdError('Please enter a High Threshold value.');
        }
        if (Number(highThreshold) > maxHighThreshold) {
            setHighThresholdError(`Maximum value for High Threshold is ${ maxHighThreshold } ${ getTempUnit() }`);
        }
        if (Number(highThreshold) < minHighThreshold) {
            setHighThresholdError(`Minimum value for High Threshold is ${ minHighThreshold } ${ getTempUnit() }`);
        }
        if (Number(lowThreshold) > maxLowThreshold) {
            setLowThresholdError(`Maximum value for Low Threshold is ${ maxLowThreshold } ${ getTempUnit() }`);
        }
        if ((lowThreshold !== '' && Number(lowThreshold) < minLowThreshold)) {
            setLowThresholdError(`Minimum value for Low Threshold is ${ minLowThreshold } ${ getTempUnit() }`);
        }
        if (lowThreshold !== '' && Number(lowThreshold) >= Number(highThreshold)) {
            setHighThresholdError('High Threshold must be greater than the Low Threshold');
        }
    };

    const resetErrors = () => {
        if (weeklyTempRise !== '') {
            setWeeklyTempRiseError('');
        }
        if (highThreshold !== '') {
            setHighThresholdError('');
        }
        if (lowThreshold !== '') {
            setLowThresholdError('');
        }
    };

    const submit = () => {
        resetErrors();
        if (isValid()) {
            const settings = {
                temperatureMonitorType: notificationType.toLowerCase(),
                highTemperatureThreshold: getTempUnit() === 'F' ? fToC(Number(highThreshold)) : Number(highThreshold),
                lowTemperatureThreshold: lowThreshold !== ''
                    ? getTempUnit() === 'F' ? fToC(Number(lowThreshold)) : Number(lowThreshold)
                    : undefined,
                weeklyTemperatureRiseThreshold: getTempUnit() === 'F'
                    ? fToC(Number(weeklyTempRise), true) : Number(weeklyTempRise),
            };
            putBinSettings(enqueueSnackbar, props.bin.id, settings);
        } else {
            setErrors();
        }
    };

    return (
        <div
            style={{
                width: '100%',
                paddingTop: '32px',
            }}
        >
            <TitlePaper
                rightTitle={(
                    <Row>
                        <Typography color="primary">
                            Temperature Settings
                        </Typography>
                        <InfoTooltip
                            id="default_tooltip_button"
                            open={TooltipStore.beginTooltips && TooltipStore.showDefaultMessages}
                            message={TooltipMessages.DEFAULT}
                            onClick={() => TooltipStore.setShowDefaultMessages(false, true)}
                        />
                    </Row>
                )}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <GridTextFieldInput
                        id="edit_notification_type_input"
                        label="Setting Type"
                        field={{
                            value: notificationType,
                            setValue: setNotificationType,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'string',
                            },
                            disabled: notificationType.toLowerCase() === 'thresholds',
                        }}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="edit_high_threshold_input"
                        label={`High Threshold (${ getTempUnit() })`}
                        field={{
                            value: highThreshold,
                            setValue: setHighThreshold,
                            error: highThresholdError,
                            setError: setHighThresholdError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                                min: '0',
                            },
                            // eslint-disable-next-line
                            disabled: notificationType.toLowerCase() !== 'thresholds' || props.bin.settings.productId === 33,
                        }}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="edit_low_threshold_input"
                        label={`Low Threshold (${ getTempUnit() })`}
                        field={{
                            value: lowThreshold,
                            setValue: setLowThreshold,
                            error: lowThresholdError,
                            setError: setLowThresholdError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                                min: '0',
                            },
                            // eslint-disable-next-line
                            disabled: notificationType.toLowerCase() !== 'thresholds' || props.bin.settings.productId === 33,
                        }}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="weekly_temp_rise_input"
                        label={`Weekly Temp. Rise (${ getTempUnit() })`}
                        field={{
                            value: weeklyTempRise,
                            setValue: setWeeklyTempRise,
                            error: weeklyTempRiseError,
                            setError: setWeeklyTempRiseError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                                min: '0',
                            },
                          //disable if the Commodity type is [Empty] or hardwareType is Direct
                          disabled: props.bin.settings.productId === 33 || isDirect,
                        }}
                        fullWidth
                    />
                </Grid>
                <SubmitButton
                    id="temp_notifications_save_changes_button"
                    text={Constants.SAVE_CHANGES}
                    onClick={submit}
                    //disable if the Commodity type is [Empty]
                    disabled={props.bin.settings.productId === 33}
                />
            </TitlePaper>
        </div>
    );
});

export default TemperatureSettingsPaper;
