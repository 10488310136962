/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {styled} from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import TextFieldInput from '../../common/input/TextFieldInput';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import ToggleButtonInput from '../../common/input/ToggleButtonInput';
import getNewId from '../../../helper/functions/getNewId';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import isEmailValid from '../../../helper/validator/isEmailValid';
import isNumeric from '../../../helper/validator/isNumeric';
import isEmailExists from '../../../helper/functions/isEmailExists';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '500px',
    padding: '16px',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
}

function AddContactDetailModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();

    const data = useMemo(() => BinsenseStore.userData.settings,
        [ BinsenseStore.userData.settings ]);

    const [ type, setType ] = useState(Constants.PHONE);

    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ phoneNumberError, setPhoneNumberError ] = useState('');

    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    let isDuplicate = false;
    let error = '';

    const reset = () => {
        isDuplicate = false;
        error = '';
    };

    const isValid = () => {
        reset();
        error = type === Constants.EMAIL ? isEmailValid(email)
            : isNumeric(phoneNumber, 10, '10-digit phone number', 10);
        if (error === '') {
            if (type === Constants.EMAIL &&
                data && data.notificationEmails.some(item => item.email === email)) {
                    isDuplicate = true;
                    error = 'The email address you are trying to add already exists on your account';
            } else if (data && data.notificationPhoneNumbers.some(item => item.phoneNumber === phoneNumber)) {
                isDuplicate = true;
                error = 'The phone number you are trying to add already exists on your account';
            }
        }
        if (type === Constants.EMAIL && error === '' ) {
            isEmailExists(enqueueSnackbar, email).then(r => {
                setEmailError(r);
            });
        }
        if (error !== '') {
            if (type === Constants.EMAIL) {
                setEmailError(error);
            } else {
                setPhoneNumberError(error);
            }
        }
        return error === '';
    };

    const submit = () => {
        if (isValid()) {
            const settings = type === Constants.PHONE ? {
                notificationPhoneNumbers: {
                    id: getNewId(data.notificationPhoneNumbers),
                    phoneNumber,
                },
            } : {
                notificationEmails: {
                    id: getNewId(data.notificationEmails),
                    email,
                },
            };
            putUserSettings(enqueueSnackbar, BinsenseStore.userData.id, settings);
            props.close();
        }
        else {
            if (type === Constants.PHONE) {
                if (isDuplicate) {
                    setPhoneNumberError(error);
                } else {
                    setPhoneNumberError('Enter a valid phone number');
                }
            }
            else {
                if (isDuplicate) {
                    setEmailError(error);
                } else {
                    setEmailError('Enter a valid email');
                }
            }
        }
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title="Add Contact Detail"
        >
            <Container>
                <ToggleButtonInput
                    value={type}
                    setValue={setType}
                    options={[
                        {
                            id: 'phone_option',
                            title: Constants.PHONE,
                        },
                        {
                            id: 'email_option',
                            title: Constants.EMAIL,
                        },
                    ]}
                />
                <TextFieldInput
                    id={`new_contact_${ type.toLowerCase() }`}
                    label={type === Constants.PHONE ? Constants.MOBILE_NUMBER : Constants.EMAIL}
                    field={{
                        value: type === Constants.EMAIL ? email : phoneNumber,
                        setValue: type === Constants.EMAIL ? setEmail : setPhoneNumber,
                        error: type === Constants.EMAIL ? emailError : phoneNumberError,
                        setError: type === Constants.EMAIL ? setEmailError : setPhoneNumberError,
                    }}
                    style={{ marginTop: '8px' }}
                />
            </Container>
        </BaseModal>
    );
}

export default AddContactDetailModal;
